/* @flow */
'use strict'

import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import './Logout.scss'

const Logout = () => (
  <div className="Logout container">
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
    </Helmet>

    <div className="column">
      <div className="Logout__title">
        Sie haben sich erfolgreich
        <br />
        abgemeldet.
      </div>

      <Link className="Button button1" to="/login">
        Zurück zum Login
      </Link>

      <div className="Logout__link">
        <a href="https://www.finabro.at/" target="_blank">
          Hier gehts zur FINABRO Homepage
        </a>
      </div>
    </div>
  </div>
)

export default Logout
