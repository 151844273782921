import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, Redirect, useHistory, useRouteMatch } from 'react-router-dom'

import { authenticate, logout } from 'core/api'
import { fetchInitialBav, logoutUser } from '../../actions/bav'

import { Overview, Employees, Service, Impressum, Privacy, Companies } from 'containers/BavArea'
import { Footer } from 'components/General'
import Header from 'components/BavArea/Header'
import MainMenu from 'components/BavArea/MainMenu'

import 'stylesheets/utils/helpers.scss'

function BavArea() {
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const username = useSelector((state) => state.bav.userData.fullname)
  const role = useSelector((state) => state.bav.userData.role)

  // on mount
  useEffect(() => authenticate(onAuthenticate), [])

  let menuItems = []
  switch (role) {
    case 2:
      menuItems = [
        {
          name: 'Übersicht',
          path: '/unternehmensportal/uebersicht',
          image: '/assets/images/ic_home.svg',
        },
        {
          name: 'Mitarbeiter',
          path: '/unternehmensportal/employees',
          image: '/assets/images/ic_mitarbeiter.png',
        },
        {
          name: 'Unternehmen',
          path: '/unternehmensportal/companies',
          image: '/assets/images/icon_family_pfade_briefcase.svg',
        },
        {
          name: 'Service',
          path: '/unternehmensportal/service',
          image: '/assets/images/icon_family_pfade_information.svg',
        },
      ]
      break
    case 9:
      menuItems = [
        {
          name: 'Übersicht',
          path: '/unternehmensportal/uebersicht',
          image: '/assets/images/ic_home.svg',
        },
        {
          name: 'Mitarbeiter',
          path: '/unternehmensportal/employees',
          image: '/assets/images/ic_mitarbeiter.png',
        },
        {
          name: 'Unternehmen',
          path: '/unternehmensportal/companies',
          image: '/assets/images/icon_family_pfade_briefcase.svg',
        },
        {
          name: 'Service',
          path: '/unternehmensportal/service',
          image: '/assets/images/icon_family_pfade_information.svg',
        },
      ]
      break
    default:
      menuItems = [
        {
          name: 'Übersicht',
          path: '/unternehmensportal/uebersicht',
          image: '/assets/images/ic_home.svg',
        },
        {
          name: 'Mitarbeiter',
          path: '/unternehmensportal/employees',
          image: '/assets/images/ic_mitarbeiter.png',
        },
        {
          name: 'Service',
          path: '/unternehmensportal/service',
          image: '/assets/images/icon_family_pfade_information.svg',
        },
      ]
  }

  return (
    <>
      <div id="BavArea" className="BavArea flexBox">
        <MainMenu menuItems={menuItems} />

        <div className="rightBlock">
          <Header username={username} onLogout={onLogout} />

          <div className="content">
            <Switch>
              <Route path={`${match.url}/uebersicht`} exact render={() => <Overview menuItems={menuItems} />} />
              <Route path={`${match.url}/employees/:companyFilter?/:id?`} exact component={Employees} />
              <Route path={`${match.url}/companies/:id?`} exact component={Companies} />
              <Route path={`${match.url}/service`} exact component={Service} />
              <Route path={`${match.url}/impressum`} exact component={Impressum} />
              <Route path={`${match.url}/privacy`} exact component={Privacy} />
              <Redirect path="/" to={`${match.url}/uebersicht`} />
              <Redirect to="/seite-nicht-gefunden" />
            </Switch>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )

  function onAuthenticate(data: Object): void {
    if (data.success) {
      if (data.bavModus) {
        const { userData, bavData, companies, partners } = data
        dispatch(fetchInitialBav(userData, bavData, companies, partners))
      } else {
        history.push('/login')
      }
    } else {
      history.push('/login')
    }
  }

  function onLogout(): void {
    logout(() => {
      dispatch(logoutUser())
      history.push('/logout')
    })
  }
}

export default BavArea
