'use strict'

// TODO:
// - move to seperate services for API calls - see example /services/api/message.js

import request from 'core/request'
import { isBavAdmin, isNumeric } from 'utils/helpers'

export const API_URL = '/api'

export function saveFile(file, name = '') {
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(fileURL)
  link.remove()
}

export function saveFileFromURL(url, name = '') {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

// export function downloadFile(url: string, name: string): void {
//   request({ url, responseType: 'blob' }).then(response => {
//     saveFile(response, name)
//   })
// }

export function transformStringValues(data) {
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key]

    // Number
    if (isNumeric(acc[key])) {
      // convert string to number
      acc[key] = Number(acc[key])
    } else {
      // Boolean, Array, Object
      try {
        acc[key] = JSON.parse(acc[key])
      } catch (error) {
        acc[key] = data[key]
      }
    }

    return acc
  }, {})
}

export function getPdf(url: string, name: string = 'document.pdf'): void {
  request({ url, responseType: 'blob' }).then((response) => {
    const file = new Blob([response], { type: 'application/pdf' })
    saveFile(file, name)
  })
}

// AUTHENTICATION
export function login(email: string, password: string, callback: (data: Object) => void): void {
  const trimmedEmail = email.trim()
  const trimmedPassword = password.trim()
  const params = JSON.stringify({ username: trimmedEmail, password: trimmedPassword, client: 'bav' })

  request({
    method: 'POST',
    url: `/session/login`,
    data: params,
  })
    .then((response) => {
      if (response.status === 'ok') {
        const userData = response.data
        if (isBavAdmin(userData.role)) {
          request('/bav')
            .then((result) => {
              request('/unternehmen')
                .then((res) => {
                  const data = {
                    success: true,
                    bavModus: true,
                    userData: response.data,
                    bavData: result.data,
                    companiesData: res.data,
                  }
                  callback(data)
                })
                .catch((error) => {
                  callback({ success: false, error })
                })
            })
            .catch((error) => {
              callback({ success: false, error })
            })
        } else {
          callback({ success: false, error: 'Ihr Konto ist nicht berechtigt.' })
        }
      } else {
        callback({ success: false })
      }
    })
    .catch((error) => {
      // TODO: error handling
      // console.error('error: ', error)
      callback({ success: false, error: error })
    })
}

export function authenticate(callback: (data: Object) => void): void {
  request(`/session?ts=${Date.now()}`)
    .then((response) => {
      if (response.status === 'ok') {
        const userData = response.data

        if (isBavAdmin(userData.role)) {
          Promise.all([request('/bav'), request('/unternehmen'), request('/partner')])
            .then(([bavResponse, companiesResponse, partnersResponse]) => {
              if (
                bavResponse.status === 'ok' &&
                companiesResponse.status === 'ok' &&
                partnersResponse.status === 'ok'
              ) {
                callback({
                  success: true,
                  bavModus: true,
                  userData: userData,
                  bavData: bavResponse.data,
                  companies: companiesResponse.data,
                  partners: partnersResponse.data,
                })
              } else {
                // TODO: error handling
                console.error('error occured when fetching initial login data')
              }
            })
            .catch((error) => {
              // TODO: error handling
              console.error('error: ', error)
              callback({ success: false, error: error })
            })
        } else {
          callback({ success: false })
        }
      } else {
        callback({ success: false })
      }
    })
    .catch((error) => {
      // TODO: error handling
      // console.error('error: ', error)
      callback({ success: false, error: error })
    })
}

export function logout(callback: () => void): void {
  request({
    method: 'POST',
    url: `/session/logout`,
  })
    .then((response) => {
      if (response.status === 'ok') {
        callback()
      } else {
        // TODO: error handling
        console.error(response.error)
      }
    })
    .catch((error) => {
      // TODO: error handling
      console.error(error)
    })
}

// PASSWORD VERGESSEN
export function resetPassword(email: string, callback: (data: Object) => void): void {
  const trimmedEmail = email.trim()
  const params = JSON.stringify({ email: trimmedEmail })

  request({
    method: 'POST',
    url: `/request/create`,
    data: params,
  })
    .then((response) => {
      if (response.status === 'ok') {
        callback({ success: true })
      } else {
        // TODO: error handling
        console.error('response error: ', response.data.error)
        callback({ success: false, error: response.data.error })
      }
    })
    .catch((error) => {
      // TODO: error handling
      console.error('error: ', error)
      callback({ success: false, error: error })
    })
}

export function updatePassword(
  email: string,
  password: string,
  secret: string,
  callback: (data: Object) => void,
): void {
  const trimmedEmail = email.trim()
  const params = JSON.stringify({
    email: trimmedEmail,
    password,
    secret,
  })

  request({
    method: 'POST',
    url: '/request/update',
    data: params,
  })
    .then((response) => {
      if (response.status === 'ok') {
        callback({ success: true })
      } else {
        // TODO: error handling
        console.error('response error: ', response.data.error)
        callback({ success: false, error: response.data.error })
      }
    })
    .catch((error) => {
      // TODO: error handling
      console.error('error: ', error)
      callback({ success: false, error: error })
    })
}

export function checkPasswordReset(email: string, secret: string, callback: (data: Object) => void): void {
  const trimmedEmail = email.trim()
  const params = {
    email: trimmedEmail,
    secret,
  }

  request({ url: '/request', params })
    .then((response) => {
      if (response.status === 'ok') {
        callback({ success: true })
      } else {
        // TODO: error handling
        console.error('response error: ', response.data.error)
        callback({ success: false, error: response.data.error })
      }
    })
    .catch((error) => {
      // TODO: error handling
      console.error('error: ', error)
      callback({ success: false, error: error })
    })
}
