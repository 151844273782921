/* @flow */
'use strict'

import type { Action } from './types'
import type { User, BavUser, Company, Partner } from '../reducers/bav'

export function fetchInitialBav(userData: User, bavData: Array<BavUser>, companiesData: Array<Company>, partnersData: Array<Partner>): Action {
  return {
    type: 'FETCH_INITIAL_BAV',
    payload: { userData, bavData, companiesData, partnersData },
  }
}

export function updateBav(bavData: Array<BavUser>): Action {
  return {
    type: 'UPDATE_BAV',
    payload: bavData,
  }
}

export function updateCompanies(bavData: Array<BavUser>, companiesData: Array<Company>): Action {
  return {
    type: 'UPDATE_COMPANIES',
    payload: { bavData, companiesData },
  }
}

export function updatePartners(partnersData: Array<Partner>): Action {
  return {
    type: 'UPDATE_PARTNERS',
    payload: partnersData,
  }
}

export function logoutUser(): Action {
  return {
    type: 'LOGOUT',
  }
}
