'use strict'

import IconExternal from 'components/UI/SVG/External'
import './Service.scss'

function Service() {
  return (
    <div className="Service">
      <div className="Service__content">
        <div className="Service__contact">
          <div className="Service__columnTitle">Kontakt</div>

          <div className="Service__item Service__item--phone">
            <div className="Service__itemImage">
              <img src="/assets/images/UI/phone.svg" />
            </div>

            <div className="Service__itemName">Telefon</div>
            <a className="Service__itemText" href="tel:+43720884146">
              +43 720 88 41 46
            </a>
          </div>

          <div className="Service__item Service__item--email">
            <div className="Service__itemImage">
              <img src="/assets/images/UI/envelope.svg" />
            </div>
            <div className="Service__itemName">E-Mail</div>
            <a className="Service__itemText" href="mailto:service@finabro.at">
              service@finabro.at
            </a>
          </div>

          <div className="Service__item Service__item--complaint">
            <div className="Service__itemImage">
              <img src="/assets/images/UI/complaint.svg" />
            </div>

            <div className="Service__itemName">Beschwerden</div>
            <a
              className="Service__itemText"
              href="https://www.finabro.at/beschwerdemanagement/'"
              target="_blank"
              rel="noopener"
            >
              <IconExternal />
            </a>
          </div>
        </div>

        <div className="Service__faq">
          <div className="Service__columnTitle">Häufig gestellte Fragen</div>

          <div className="Service__item Service__item--faq">
            <div className="Service__itemImage">
              <img src="/assets/images/UI/faq.svg" />
            </div>

            <div className="Service__itemName">BAV-FAQs</div>
            <a className="Service__itemText" href="https://www.finabro.at/bav/faq/" target="_blank" rel="noopener">
              <IconExternal />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service
