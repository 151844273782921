/* @flow */
'use strict'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

type Props = {
  menuItems: Array<Object>,
}

type State = {
  // state
}

class Overview extends Component {
  props: Props
  state: State

  render() {
    const items = this.props.menuItems.slice(1)

    return (
      <div id="Overview">
        <h2>Übersicht</h2>
        <div className="linkCard">
          <Link to={items[0].path}>
            <span>
              <img src={items[0].image} />
              {items[0].name}
            </span>
          </Link>
        </div>
        <div style={{ display: 'flex' }}>
          {items.map((menu, index) => {
            if (index >= 1 && index <= 3) {
              return (
                <div key={index} className="linkCard">
                  <Link to={menu.path}>
                    <span>
                      <img src={menu.image} />
                      {menu.name}
                    </span>
                  </Link>
                </div>
              )
            }
          })}
        </div>
        <div style={{ display: 'flex' }}>
          {items.map((menu, index) => {
            if (index >= 4) {
              return (
                <div key={index} className="linkCard">
                  <Link to={menu.path}>
                    <span>
                      <img src={menu.image} />
                      {menu.name}
                    </span>
                  </Link>
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: Object) => {
  return {}
}

export default connect(mapStateToProps)(Overview)
