'use strict'

import { Link } from 'react-router-dom'

import { Footer } from 'components/General'
import Logo from 'components/UI/Logo'
import { defaultResources } from 'resources'

let resources = defaultResources

const NoMatch = () => {
  return (
    <div className="NoMatch">
      <div className="NoMatch__content">
        <Logo />

        <h2>
          Error <span>//</span> 404
        </h2>

        <h1>Hoppla, diese Seite existiert leider nicht</h1>

        <Link className="button button--secondary" to="/">
          Zur Startseite
        </Link>
      </div>

      <Footer />
    </div>
  )
}

export default NoMatch
