

const SvgLogoFinabro = props => (
  <svg viewBox="0 0 224.44 107.42" {...props}>
    <path
      d="M171.41 26.83q-1.74 2.37-3.25 4.88A65.39 65.39 0 0059.3 26.83q-1.73 2.37-3.24 4.88A65.38 65.38 0 000 0v18.69A46.71 46.71 0 0146.71 65.4h18.68a46.71 46.71 0 0193.42 0h18.68a46.72 46.72 0 0146.71-46.71V0a65.26 65.26 0 00-52.79 26.83zM2.33 88.89v7.13h11.12v2.26H2.33v8.89H0V86.63h14.83v2.23zm33.9 18.28h-2.32V86.63h2.32zm38.6-20.54v20.51h-2.74L59.41 89.85v17.32h-2.32V86.63h2.74l12.68 17.26V86.63zm38.73 20.54h-2.58l-2.51-6H98.31l-2.51 6h-2.58l8.71-20.54h2.9zm-6-8.09l-4.18-10-4.18 10zm40.12-7a4.61 4.61 0 01-2.76 4.62 5.06 5.06 0 013.81 5.08q0 3.72-3.93 5a9.77 9.77 0 01-3.07.43H132V86.63h9.2a6.73 6.73 0 015 1.79 4.92 4.92 0 011.48 3.66zm-13.36 3.75h6.88q2.85 0 3.81-2a3.24 3.24 0 00.34-1.47 4.25 4.25 0 00-.21-1.4 3 3 0 00-.69-1.07 3.93 3.93 0 00-3-1.08h-7.13zm7.65 9.14a5.28 5.28 0 003.29-.88 3 3 0 001.15-2.5 3.28 3.28 0 00-1.12-2.54 5.17 5.17 0 00-3.56-1.11h-7.41v7zm28.7 2.2h-2.32V86.63h9.82a7.15 7.15 0 015 1.73 6 6 0 011.89 4.68 5.2 5.2 0 01-3.19 5.24 9.34 9.34 0 01-1.76.59l5.39 8.27h-2.76l-5-7.87h-7.13zm0-10.07h7.1q3.28 0 4.53-2a4.34 4.34 0 00-.87-5.07 5.26 5.26 0 00-3.54-1.13h-7.22zm43.36-10.69a10 10 0 017.53 3.07 11 11 0 010 14.87 10.77 10.77 0 01-15.06 0 11 11 0 010-14.87 10 10 0 017.53-3.07zm0 18.75a7.79 7.79 0 005.92-2.39 9 9 0 000-11.71 8.53 8.53 0 00-11.84 0 9 9 0 000 11.71 7.79 7.79 0 005.92 2.39z"
      fill="#0e3f60"
    />
  </svg>
)

export default SvgLogoFinabro
