import axios from 'axios'

const API_URL = 'api'
const AUTH_TOKEN = btoa(`finabro:asdf`)
// TODO: add environment vars in jenkins
// const AUTH_TOKEN = btoa(`${HTTP_USERNAME}:${HTTP_PASSWORD}`)

axios.defaults.baseURL = location.origin
axios.defaults.headers.common['Authorization'] = `Basic ${AUTH_TOKEN}`
axios.defaults.headers.post['Content-Type'] = 'application/json'

// create axios client for API requests
const client = axios.create({
  baseURL: `${location.origin}/${API_URL}`,
  auth: { Authorization: `Basic ${AUTH_TOKEN}` },
  headers: { post: { 'Content-Type': 'application/json' } },
})

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options) {
  const onSuccess = response => response.data

  const onError = function(error) {
    console.error('Request Failed:', error.config)

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status)
      console.error('Data:', error.response.data)
      console.error('Headers:', error.response.headers)
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message)
    }

    return Promise.reject(error.response || error.message)
  }

  return client(options)
    .then(onSuccess)
    .catch(onError)
}

export default request
