'use strict'

import axios from 'axios'
import { sortBy } from 'lodash'
import moment from 'moment'

export const INSIDE_URL = PROD ? 'https://inside.finabro.at' : 'https://dev.finabro.com'

export const SERVER = PROD
  ? window.location.protocol + '//' + window.location.host + '/api'
  : STAGE || DEV
  ? window.location.protocol + '//' + window.location.host + '/api'
  : LOCAL
  ? window.location.protocol + '//' + window.location.host
  : 'http://localhost:8888/finabro/server'

const AUTH_TOKEN = 'sB9Xl1JYKc2JwQ'
// export const API_URL = '/api'

/// axios
axios.defaults.baseURL = SERVER
axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
axios.defaults.headers.post['Content-Type'] = 'application/json'

export function isBavAdmin(role: number): boolean {
  return role === 2 || role === 4 || role === 9
}

export function isValidEmail(email: ?string): boolean {
  return typeof email === 'string' && !!email
  // return typeof email === 'string' && isEmail(email)
}

// LAYOUT helpers
// const PORTRAIT = 0
// const LANDSCAPE = 90
export const MAX_SMALL_MOBILE_WIDTH = 479
export const MAX_MOBILE_WIDTH = 767
export const MAX_TABLET_WIDTH = 980

export function getWindowWidth() {
  const windowWidth =
    window.screen.width && window.innerWidth && window.innerWidth > window.screen.width
      ? window.screen.width
      : window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  return windowWidth
}

// format value with thousands separator, without decimal places
export function formatValue(value: number, rounding?: boolean): string {
  if (!rounding) {
    // uses bitwise operator to truncate decimal places to avoid unintended rounding up/down
    value = value | 0
  } else {
    value = Math.round(value)
  }

  // NOTE: below does not work on mobile
  // return value.toLocaleString('de-DE', { useGrouping: true })

  return value
    .toString()
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export function isNumeric(number) {
  return typeof number !== 'boolean' ? +number === +number : false
}

export function updateEmployee(
  id: number,
  firstname: string,
  lastname: string,
  address: string,
  houseNumber: string,
  staircase: string,
  doorNumber: string,
  plz: number,
  ort: string,
  email: string,
  birthday: string,
  personalNummer: string,
  callback: () => void,
): void {
  const params = JSON.stringify({
    id,
    user_vorname: firstname,
    user_nachname: lastname,
    user_strasse: address,
    doorNumber: doorNumber,
    staircase: staircase,
    houseNumber: houseNumber,
    user_plz: plz,
    user_ort: ort,
    user_email: email,
    user_geburtsDatum: birthday,
    user_personalnummer: personalNummer,
  })
  axios
    .post('/benutzer/update', params)
    .then((response) => {
      if (response.data.status == 'ok') {
        axios.get('/bav').then((result) => {
          const data = {
            success: true,
            bavData: result.data.data,
          }
          callback(data)
        })
      } else {
        callback({ success: false, error: response.data.data.error })
      }
    })
    .catch((error) => callback({ success: false, error: error.message }))
}

export function updateEmployeeStatus(
  productId: number,
  statusId: number,
  date: string,
  value: number,
  approved: boolean,
  callback: () => void,
): void {
  const params = JSON.stringify({
    produktid: productId,
    id: 0,
    datum: date,
    status: value,
    approved,
  })

  // NOTE: why delete?
  //GitHub-Ticket #13 - Delete as Workaround for changing Date when State is already "Karenz" (=2)
  axios
    .post(
      '/bavstatus/delete',
      JSON.stringify({
        produktid: productId,
        bavstatusid: statusId,
      }),
    )
    .then(() => {
      axios
        .post('/bavstatus/save', params)
        .then((response) => {
          if (response.data.status == 'ok') {
            axios.all([axios.get(`/bavstatus?produktid=${productId}`), axios.get('/bav')]).then(
              axios.spread((bavStatusResponse, bavResponse) => {
                if (bavStatusResponse.data.status === 'ok' && bavResponse.data.status === 'ok') {
                  const sortedResponseOfStatus = sortBy(bavStatusResponse.data.data, (e) => {
                    return new moment(e.timestamp, 'DD.MM.YYYY kk:mm:ss')
                  }).filter((e) => {
                    if (e.status.id == value) return e
                  })

                  const data = {
                    success: true,
                    status: sortedResponseOfStatus[sortedResponseOfStatus.length - 1],
                    bavData: bavResponse.data.data,
                  }
                  callback(data)
                }
              }),
            )
          } else {
            callback({ success: false, error: response.data.data.error })
          }
        })
        .catch((error) => callback({ success: false, error: error.message }))
    })
}

export function deleteEmployeeStatus(productId: number, statusId: number, callback: () => void): void {
  axios
    .post(
      '/bavstatus/delete',
      JSON.stringify({
        produktid: productId,
        bavstatusid: statusId,
      }),
    )
    .then((response) => {
      if (response.data.status == 'ok') {
        axios.get('/bav').then((bavResponse) => {
          if (bavResponse.data.status === 'ok') {
            const data = {
              success: true,
              bavData: bavResponse.data.data,
            }
            callback(data)
          }
        })
      }
    })
}

export function getCompanies(callback: () => void): void {
  axios.get('/unternehmen').then((response) => {
    if (response.data.status == 'ok') {
      axios.get('/bav').then((result) => {
        if (result.data.status == 'ok') {
          callback({
            success: true,
            bavData: result.data.data,
            companiesData: response.data.data,
          })
        }
      })
    } else {
      callback({ success: false })
    }
  })
}

export function updateCompany(
  id: number,
  name: string,
  strasse: string,
  plz: number,
  ort: string,
  land: string,
  callback: () => void,
): void {
  axios
    .post(
      '/unternehmen/update',
      JSON.stringify({
        id,
        name,
        strasse,
        plz,
        ort,
        land,
      }),
    )
    .then((response) => {
      if (response.data.status == 'ok') {
        callback({ success: true })
      } else {
        callback({ success: false })
      }
    })
}

export function isAdmin(role: number): boolean {
  return role === 2 || role === 8
}

export function isSuperAdmin(role: number): boolean {
  return role === 2
}

export function isBasicAdmin(role: number): boolean {
  return role === 8
}
