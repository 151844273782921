/* @flow */
'use strict'

import React, { Component } from 'react'
import { connect } from 'react-redux'

type Props = {
  // props
}

type State = {
  // state
}


class Impressum extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // this.state = {}

    // const _Container = (this: any)
    // _Container.onEvent = this.onEvent.bind(this)
  }

  /* Component Lifecycle */

  /// Mounting
  // componentDidMount() {}
  // componentWillUnmount() {}

  /// Updating
  // shouldComponentUpdate(nextProps: Props, nextState: State) {}
  // componentDidUpdate(prevProps: Props, prevState: State) {}

  render() {
    return (
      <div id="Impressum">
        <h2>Impressum</h2>
        <div className="bold nomargin">Firma (Medieninhaber):</div>
        <div className="nomargin">FINABRO GmbH</div>
        <div className="nomargin">Armbrustergasse 13-1</div>
        <div className="nomargin">1190 Wien</div>
        <div>Österreich</div>
        <div className="bold nomargin">Geschäftsführung:</div>
        <div className="nomargin">Sören Obling, MSc</div>
        <div>Mag. Oliver Lintner</div>
        <div className="bold nomargin">Firmenbuch & Gericht</div>
        <div className="nomargin">FN 447303 z</div>
        <div>Handelsgericht Wien</div>
        <div className="bold">Gewerbe</div>
        <div>Gewerbliche Vermögensberatung (§136a – GISA-Zahl 29266173)  mit den Berechtigungen nach § 1 Z 20 Wertpapieraufsichtsgesetz als vertraglich gebundener Vermittler und zur Vermittlung von Lebens- und Unfallversicherungen in der Form Versicherungsagent, Register für Versicherungs- und Kreditvermittler.</div>
        <div>Die Überprüfung der Eintragung ist online im <a href="https://www.gisa.gv.at/versicherungsvermittlerregister" target="_blank">Versicherungsvermittlerregister</a> möglich. Beschwerden können an die <a href="https://www.bmwfw.gv.at/Unternehmen/versicherungsvermittler/Seiten/BeschwerdestelleueberVersicherungsvermittler.aspx" target="_blank">Beschwerdestelle für Versicherungsvermittlung des bmwfw</a> gerichtet werden.</div>
        <div className="nomargin">Magistrat der Stadt Wien – Magistratisches Bezirksamt für den 18./19. Bezirk</div>
        <div className="nomargin">Versicherungsvermittlung als Agent der Helvetia Versicherung AG</div>
        <div>Vertraglich gebundener Vermittler der P&F Portfolio und Finanzmanagement GmbH</div>
        <div className="bold nomargin">UID Nummer</div>
        <div>ATU71450315</div>
        <div className="bold nomargin">Kontakt:</div>
        <div className="nomargin"><a href="tel:00437208844146">+43 720 88 41 46</a></div>
        <div><a href="mailto:office@finabro.com">office@finabro.com</a></div>
        <a href="https://www.wkoecg.at/Web/Ecg.aspx?FirmaID=1a6d3917-9446-40f9-82b2-c8a505e4652b" target="_blank" rel="noopener noreferrer" style={{ display: "block", margin: "30px 0"}}><img src="https://www.wkoecg.at/Web/Library/img/ecg.gif" alt="Informationen zu E-Commerce und Mediengesetz" /></a>
      </div>
    )
  }


  /// Event Handlers
  // ...
}

const mapStateToProps = (state: Object) => {
  // console.log('_Container mapStateToProps:')
  // console.log('  state: ', state)

  return {
    // main: state.main,
  }
}

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Impressum)
