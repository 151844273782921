/* @flow */
'use strict'

import type { Action } from '../actions/types'


type State = {
}


const initialState: State = {
}


export default function mainReducer(
  state: State = initialState, action: Action
): State {
  switch (action.type) {
    default:
      return state
  }
}
