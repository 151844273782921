/* @flow */
'use strict'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-modal'

import ReactTable from 'react-table'
import 'react-table/react-table.css'

import {
  TextField, FormControl, FormControlLabel, FormGroup, Checkbox, FormLabel,
  RadioGroup, Radio, Button
} from '@material-ui/core';

import { Company } from '../../reducers/bav'
import type { Dispatch } from '../../actions/types'
import { updateCompanies } from '../../actions/bav'

import { getCompanies, updateCompany } from '../../utils/helpers'

type Props = {
  dispatch: Dispatch,
  companiesData: Array<Company>,
}

type State = {
  searchTerm: string,
  KLVChecked: boolean,
  FLVChecked: boolean,
  umwandlungChecked: boolean,
  erhoehungChecked: boolean,
  selectedCompany: Object,
  editCompany: Object,
  editError: Object,
  editSuccess: string,
}

class Companies extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      searchTerm: '',
      KLVChecked: false,
      FLVChecked: false,
      umwandlungChecked: false,
      erhoehungChecked: false,
      selectedCompany: {},
      editCompany: {},
      editError: {},
      editSuccess: ''
    }

    this.handleEditChange = this.handleEditChange.bind(this)
    this.submitEdit = this.submitEdit.bind(this)
  }

  componentDidMount() {
    getCompanies((data) => {
      if ( data.success ) {
        this.props.dispatch(updateCompanies(data.bavData, data.companiesData))
        if ( !! this.props.match.params.id ) {
          const id = Number(this.props.match.params.id)
          const selected = this.props.companiesData[this.props.companiesData.map((e) => { return e.id }).indexOf(id)]
          this.setState({ selectedCompany: selected, editCompany: selected, editError: {}, editSuccess: '' })
        }
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.state.data = nextProps.bavData
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if ( prevProps.match.params.id != this.props.match.params.id ) {
      if ( !! this.props.match.params.id ) {
        const id = Number(this.props.match.params.id)
        getCompanies((data) => {
          if ( data.success ) {
            this.props.dispatch(updateCompanies(data.bavData, data.companiesData))
            const selected = this.props.companiesData[this.props.companiesData.map((e) => { return e.id }).indexOf(id)]
            this.setState({ selectedCompany: selected, editCompany: selected, editError: {}, editSuccess: '' })
          }
        })
      } else {
        this.setState({ selectedCompany: {}, editError: {}, editSuccess: '' })
      }
    }
  }

  render() {
    const { companiesData, history } = this.props
    const {
      searchTerm, KLVChecked, FLVChecked, umwandlungChecked, erhoehungChecked,
      selectedCompany, editCompany, editError, editSuccess
    } = this.state

    const columns = [{
      Header: 'Name',
      accessor: 'name',
      headerClassName: 'sortable',
      sortable: true,
      width: 210
    }, {
      Header: 'Anzahl Mitarbeiter',
      accessor: 'anzMitarbeiter',
      sortable: true,
      width: 210
    }, {
      Header: 'Versicherung',
      Cell: (row) => (
        !! row.original.hasFLV
        ? !! row.original.hasKLV
          ? <div>KLV, FLV</div>
          : <div>FLV</div>
        : !! row.original.hasKLV
          ? <div>KLV</div>
          : <div></div>
      ),
      width: 210
    }, {
      Header: 'Variante',
      Cell: (row) => (
        !! row.original.isUmwandlung
        ? <div>Umwandlung</div>
        : <div>Erhöhung</div>
      ),
      width: 210
    }, {
      Header: 'Aktionen',
      Cell: (row) => (
        <div
          className="editButton"
          onClick={(event) => {
            event.stopPropagation()
            history.push('/companies/' + row.original.id)
          }}
        ></div>
      ),
      width: 106.16
    }]

    const filteredData = companiesData.filter((company) => {
      return company.name.toLowerCase().indexOf(searchTerm) > -1
        && ( ! KLVChecked && ! FLVChecked 
        || KLVChecked && company.hasKLV
        || FLVChecked && company.hasFLV )
        && ( umwandlungChecked == erhoehungChecked
        || umwandlungChecked && company.isUmwandlung
        || erhoehungChecked && ! company.isUmwandlung )
    })

    return (
      <div id="Companies">
        <h2>Unternehmen</h2>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '250px' }}>
                <TextField
                  id="search"
                  label="Suche"
                  style={{ width: '250px', margin: '10px 20px 10px 0' }}
                  onChange={(event) => this.setState({ searchTerm: event.target.value })}
                  className="searchBar input"
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={KLVChecked}
                      onChange={(event) => this.setState({ KLVChecked: event.target.checked })}
                    />
                  }
                  label="KLV"
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={FLVChecked}
                      onChange={(event) => this.setState({ FLVChecked: event.target.checked })}
                    />
                  }
                  label="FLV"
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={umwandlungChecked}
                      onChange={(event) => this.setState({ umwandlungChecked: event.target.checked })}
                    />
                  }
                  label="Umwandlung"
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={erhoehungChecked}
                      onChange={(event) => this.setState({ erhoehungChecked: event.target.checked })}
                    />
                  }
                  label="Erhöhung"
                />
              </td>
              <td><div>Anzahl Unternehmen: { filteredData.length }</div></td>
            </tr>
          </tbody>
        </table>
        <ReactTable
          data={filteredData}
          noDataText="Keine Daten vorhanden."
          showPagination={false}
          pageSize={filteredData.length > 9 ? filteredData.length : 9}
          columns={columns}
          className="companiesTable -highlight"
          style={{ marginTop: '10px' }}
          getTdProps={(state, row, column, instance) => {
            return {
              onClick: () => history.push('/unternehmensportal/employees/' + row.original.name + '/')
            }
          }}
        />
        <Modal
          isOpen={ Object.keys(selectedCompany).length > 0 }
          onRequestClose={() => history.push('/companies')}
          className="modalContent"
          overlayClassName="modalOverlay"
          portalClassName="CompanyDetail"
        >
          <img
            src="/assets/images/close.png"
            className="closeButton"
            onClick={() => history.push('/unternehmensportal/companies')}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3>Unternehmen editieren</h3>
            <TextField
              label="Unternehmensname"
              value={editCompany.name}
              onChange={(event: Object) => this.handleEditChange('name', event.target.value)}
              className="input"
            />
            { !! editError.name && (
                <div className="error">{ editError.name }</div>
              )
            }
            <TextField
              label="Straße"
              value={editCompany.strasse}
              onChange={(event) => this.handleEditChange('strasse', event.target.value)}
              className="input"
            />
            <TextField
              label="PLZ"
              value={editCompany.plz}
              onChange={(event) => this.handleEditChange('plz', event.target.value)}
              className="input"
            />
            { !! editError.plz && (
                <div className="error">{ editError.plz }</div>
              )
            }
            <TextField
              label="Ort"
              value={editCompany.ort}
              onChange={(event) => this.handleEditChange('ort', event.target.value)}
              className="input"
            />
            <TextField
              label="Land"
              value={editCompany.land}
              onChange={(event) => this.handleEditChange('land', event.target.value)}
              className="input"
            />
            <FormControl className="input">
              <FormLabel>Produkt-Typ</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={editCompany.hasKLV} onChange={(event) => this.handleEditChange('hasKLV', event.target.checked)} />}
                  className="choice"
                  label="KLV"
                />
                <FormControlLabel
                  control={<Checkbox checked={editCompany.hasFLV} onChange={(event) => this.handleEditChange('hasFLV', event.target.checked)} />}
                  label="FLV"
                />
              </FormGroup>
            </FormControl>
            <FormControl className="input">
              <FormLabel>Variante</FormLabel>
              <RadioGroup
                value={JSON.stringify(editCompany.isUmwandlung)}
                onChange={(event) => this.handleEditChange('isUmwandlung', JSON.parse(event.target.value))}
                row
              >
                <FormControlLabel value="false" control={<Radio />} label="Erhöhung" className="choice" />
                <FormControlLabel value="true" control={<Radio />} label="Umwandlung" className="choice" />
              </RadioGroup>
            </FormControl>
            <FormControl className="input">
              <FormLabel>Zahlungsinterval</FormLabel>
              <RadioGroup
                value={editCompany.interval}
                onChange={(event) => this.handleEditChange('interval', event.target.value)}
                row
              >
                <FormControlLabel value="monatlich" control={<Radio />} label="monatlich" className="choice" />
                <FormControlLabel value="jährlich" control={<Radio />} label="jährlich" className="choice" />
              </RadioGroup>
            </FormControl>
            <TextField
              label="Mitarbeiter-Gesamtanzahl"
              type="number"
              value={editCompany.anzahl}
              onChange={(event: Object) => this.handleEditChange('anzahl', event.target.value)}
              className="input"
            />
            <Button
              variant="outlined"
              color="primary"
              className="button primary outlined"
              style={{ marginTop: '30px' }}
              onClick={this.submitEdit}
            >
              Speichern
            </Button>
            { !! editSuccess && (
                <div className="success">{ editSuccess }</div>
              )
            }
          </div>
        </Modal>
      </div>
    )
  }

  handleEditChange(selector: string, value: boolean|string): void {
    const editCompany = this.state.editCompany

    this.setState({ editCompany: {
      ...editCompany,
      [selector]: value
    } })
  }

  submitEdit(): void {
    const { editCompany } = this.state

    let editError = {}

    if ( ! editCompany.name ) {
      editError = {
        ...editError,
        name: 'Darf nicht leer sein'
      }
    }
    if ( isNaN(editCompany.plz) ) {
      editError = {
        ...editError,
        plz: 'Muss eine Zahl sein'
      }
    }

    if ( Object.keys(editError).length <= 0 ) {
      updateCompany(
        editCompany.id,
        editCompany.name,
        editCompany.strasse,
        Number(editCompany.plz),
        editCompany.ort,
        editCompany.land,
        (data) => {
          if ( data.success ) {
            this.setState({ editError: {}, editSuccess: 'Änderungen erfolgreich gespeichert' })
            getCompanies((data) => {
              if ( data.success ) {
                this.props.dispatch(updateCompanies(data.bavData, data.companiesData))
              }
            })
          } else {
            this.setState({ editError: {
              error: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es wieder später'
            }, editSuccess: ''})
          }
        }
      )
    } else {
      this.setState({ editError, editSuccess: '' })
    }
  }
}

const mapStateToProps = (state: Object) => {
  return {
    companiesData: state.bav.companiesData,
  }
}

export default connect(mapStateToProps)(withRouter(Companies))
