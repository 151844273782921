/* @flow */
'use strict'

require('./BavArea.scss')

import BavArea from './BavArea.js'
import Overview from './Overview.js'
import Employees from './Employees.js'
import Service from './Service.js'
import Impressum from './Impressum.js'
import Privacy from './Privacy.js'
import Companies from './Companies.js'

export { BavArea, Overview, Employees, Service, Impressum, Privacy, Companies }
