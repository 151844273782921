/* @flow */
'use strict'

import { sortBy } from 'lodash'
import axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Modal from 'react-modal'
import { DatePicker } from '@material-ui/pickers'
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Input,
  InputLabel,
} from '@material-ui/core'

import { BavUser } from '../../reducers/bav'
import date from 'utils/date'
import { updateEmployee, updateEmployeeStatus, deleteEmployeeStatus, isSuperAdmin } from 'utils/helpers'
import type { Dispatch } from '../../actions/types'
import { updateBav } from '../../actions/bav'

type Props = {
  dispatch: Dispatch,
  bavData: Array<BavUser>,
  role: number,
}

type State = {
  minYear: ?number,
  activeChecked: ?boolean,
  inactiveChecked: ?boolean,
  searchTerm: ?string,
  companyFilter: ?string,
  selected: ?Object,
  allStati: ?Object,
  status: ?Object,
  statusError: ?string,
  statusSuccess: ?string,
  data: ?Array<BavUser>,
  isEditing: boolean,
  editFirstname: ?string,
  editLastname: ?string,
  editAddress: ?string,
  editHouseNumber: ?string,
  editStaircase: ?string,
  editDoorNumber: ?string,
  editPlz: ?number,
  editOrt: ?string,
  editEmail: ?string,
  editBirthday: ?string,
  editPersonalNummer: ?number,
  editError: ?string,
  editSuccess: ?string,
  disableButton: ?boolean,
  disableApprove: ?boolean,
  allDocuments: ?Object,
  insuranceType: ?string,
}

Modal.setAppElement('#app')

class Employees extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      minYear: moment().year(),
      activeChecked: true,
      inactiveChecked: true,
      searchTerm: '',
      companyFilter: '',
      selected: null,
      status: null,
      data: props.bavData,
      isEditing: false,
      editFirstname: '',
      editLastname: '',
      editAddress: '',
      editHouseNumber: '',
      editStaircase: '',
      editDoorNumber: '',
      editPlz: null,
      editOrt: '',
      editEmail: '',
      editBirthday: '',
      editPersonalNummer: '',
      editError: '',
      editSuccess: '',
      disableButton: true,
      disableApprove: true,
      insuranceType: '',
    }

    this.handleEditSubmit = this.handleEditSubmit.bind(this)
    this.handleSaveSubmit = this.handleSaveSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.determineSave = this.determineSave.bind(this)
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    if (!!match.params.companyFilter) {
      this.setState({ companyFilter: match.params.companyFilter })
    }

    if (!!match.params.id) {
      const id = Number(match.params.id)

      axios.get('/bav').then((res) => {
        const bavData = res.data.data
        const selectedBav = bavData[bavData.map((e) => e.id).indexOf(id)]
        const bavID = selectedBav.status.id
        const bavStatusID = selectedBav.status.status.id

        dispatch(updateBav(bavData))

        axios.get(`/bavstatus?produktid=${selectedBav.produktid}`).then((response) => {
          axios.get('/document/list?benutzerid=' + selectedBav.id).then((listResponse) => {
            axios.get('/produkt?id=' + selectedBav.id).then((productResponse) => {
              let insHelper = ''
              if (productResponse.data.status == 'ok') {
                insHelper =
                  productResponse.data.data[0].produktmantel.id == 2
                    ? 'Fondsgebundene Lebensversicherung'
                    : productResponse.data.data[0].produktmantel.id == 3
                    ? 'Klassische Lebensversicherung'
                    : ''
              }
              let documents = null
              if (listResponse.data.status == 'ok') {
                documents = [
                  listResponse.data.data.indexOf('willkommenspaket') > -1
                    ? {
                        name: 'Vertrag über die digitale Vermögensverwaltung',
                        link: '/document/willkommenspaket?benutzerid=' + selectedBav.id,
                      }
                    : null,
                  listResponse.data.data.indexOf('sepamandat') > -1
                    ? {
                        name: 'SEPA-Lastschriftmandat',
                        link: '/document/sepamandat?benutzerid=' + selectedBav.id,
                      }
                    : null,
                  listResponse.data.data.indexOf('depotvertrag') > -1
                    ? {
                        name: 'Kontoeröffnungsantrag',
                        link: '/document/depotvertrag?benutzerid=' + selectedBav.id,
                      }
                    : null,
                  listResponse.data.data.indexOf('vollmacht') > -1
                    ? {
                        name: 'Vollmacht für den Vermögensverwalter und für die Bank',
                        link: '/document/vollmacht?benutzerid=' + selectedBav.id,
                      }
                    : null,
                  listResponse.data.data.indexOf('freistellungsauftrag') > -1
                    ? {
                        name: 'Freistellungsauftrag für Kapitalerträge',
                        link: '/document/freistellungsauftrag?benutzerid=' + selectedBav.id,
                      }
                    : null,
                ]
              }

              let allStatiHelp = {}
              let status = {}
              let selectedStatus = {}
              const time = moment()
              const sortedResponse = sortBy(response.data.data, (e) => {
                return new moment(e.timestamp, 'DD.MM.YYYY kk:mm:ss')
              })
              const sortedActive = sortedResponse.filter((e) => {
                if (e.status.id == 1) return e
              })
              const sortedMaternity = sortedResponse.filter((e) => {
                if (e.status.id == 2) return e
              })
              const sortedLeave = sortedResponse.filter((e) => {
                if (e.status.id == 3) return e
              })
              const sortedEnd = sortedResponse.filter((e) => {
                if (e.status.id == 4) return e
              })

              if (bavStatusID == 1) {
                allStatiHelp.active = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

                allStatiHelp.maternity =
                  sortedMaternity.length > 0 &&
                  new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedMaternity[sortedMaternity.length - 1]
                    : null
                if (!!allStatiHelp.maternity) {
                  const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                  for (let i = 1; index + i < sortedResponse.length; i++) {
                    if (
                      sortedResponse[index + i].status.id == 1 &&
                      new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                        new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                      )
                    ) {
                      allStatiHelp.maternity.active = sortedResponse[index + i]
                      break
                    }
                  }
                }

                allStatiHelp.leave =
                  sortedLeave.length > 0 &&
                  new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedLeave[sortedLeave.length - 1]
                    : null

                allStatiHelp.end =
                  sortedEnd.length > 0 && new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedEnd[sortedEnd.length - 1]
                    : null

                selectedStatus = allStatiHelp.active
              } else if (bavStatusID == 2) {
                allStatiHelp.active =
                  sortedActive.length > 0 &&
                  new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedActive[sortedActive.length - 1]
                    : null

                allStatiHelp.maternity = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

                if (!!allStatiHelp.maternity) {
                  const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                  for (let i = 1; index + i < sortedResponse.length; i++) {
                    if (
                      sortedResponse[index + i].status.id == 1 &&
                      new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                        new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                      )
                    ) {
                      allStatiHelp.maternity.active = sortedResponse[index + i]
                      break
                    }
                  }
                }

                allStatiHelp.leave =
                  sortedLeave.length > 0 &&
                  new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedLeave[sortedLeave.length - 1]
                    : null

                allStatiHelp.end =
                  sortedEnd.length > 0 && new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedEnd[sortedEnd.length - 1]
                    : null

                selectedStatus = allStatiHelp.maternity
              } else if (bavStatusID == 3) {
                allStatiHelp.active =
                  sortedActive.length > 0 &&
                  new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedActive[sortedActive.length - 1]
                    : null

                allStatiHelp.maternity =
                  sortedMaternity.length > 0 &&
                  new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedMaternity[sortedMaternity.length - 1]
                    : null
                if (!!allStatiHelp.maternity) {
                  const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                  for (let i = 1; index + i < sortedResponse.length; i++) {
                    if (
                      sortedResponse[index + i].status.id == 1 &&
                      new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                        new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                      )
                    ) {
                      allStatiHelp.maternity.active = sortedResponse[index + i]
                      break
                    }
                  }
                }

                allStatiHelp.leave = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

                allStatiHelp.end =
                  sortedEnd.length > 0 && new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedEnd[sortedEnd.length - 1]
                    : null

                selectedStatus = allStatiHelp.leave
              } else if (bavStatusID == 4) {
                allStatiHelp.active =
                  sortedActive.length > 0 &&
                  new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedActive[sortedActive.length - 1]
                    : null

                allStatiHelp.maternity =
                  sortedMaternity.length > 0 &&
                  new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedMaternity[sortedMaternity.length - 1]
                    : null
                if (!!allStatiHelp.maternity) {
                  const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                  for (let i = 1; index + i < sortedResponse.length; i++) {
                    if (
                      sortedResponse[index + i].status.id == 1 &&
                      new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                        new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                      )
                    ) {
                      allStatiHelp.maternity.active = sortedResponse[index + i]
                      break
                    }
                  }
                }

                allStatiHelp.leave =
                  sortedLeave.length > 0 &&
                  new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                    ? sortedLeave[sortedLeave.length - 1]
                    : null

                allStatiHelp.end = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

                selectedStatus = allStatiHelp.end
              }

              const selectedHelp = {
                ...selectedBav,
                fullname: selectedBav.vorname + ' ' + selectedBav.nachname,
                status: bavStatusID,
                polizzennummer: productResponse.data.data[0].depot.nummer,
              }

              if (!selectedStatus) {
                selectedStatus = selectedBav.status
              }

              const statusHelp = {
                approved: selectedStatus.approved,
                value: selectedStatus.status.id,
                startDate: selectedStatus.datum,
                startID: selectedStatus.id,
                endDate: !!selectedStatus.active ? selectedStatus.active.datum : null,
                endID: !!selectedStatus.active ? selectedStatus.active.id : null,
                month: moment(selectedStatus.datum, 'DD.MM.YYYY').month() + 1,
                year: moment(selectedStatus.datum, 'DD.MM.YYYY').year(),
              }

              console.log('selectedHelp', selectedHelp)

              this.setState({
                selected: selectedHelp,
                status: statusHelp,
                allStati: allStatiHelp,
                isEditing: false,
                editFirstname: selectedHelp.vorname,
                editLastname: selectedHelp.nachname,
                editAddress: selectedHelp.strasse,
                editHouseNumber: selectedHelp.houseNumber,
                editStaircase: selectedHelp.staircase,
                editDoorNumber: selectedHelp.doorNumber,
                editPlz: selectedHelp.plz,
                editOrt: selectedHelp.ort,
                editEmail: selectedHelp.email,
                editBirthday: selectedHelp.geburtsDatum,
                editPersonalNummer: selectedHelp.personalnummer,
                disableButton: true,
                disableApprove: selectedStatus.approved,
                allDocuments: !!documents ? documents.filter(Boolean) : null,
                insuranceType: insHelper,
              })
            })
          })
        })
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.state.data = nextProps.bavData
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!!prevProps.match.params && !!this.props.match.params) {
      if (prevProps.match.params.companyFilter != this.props.match.params.companyFilter) {
        this.setState({ companyFilter: this.props.match.params.companyFilter })
      }

      if (prevProps.match.params.id != this.props.match.params.id) {
        const { dispatch } = this.props
        if (!!this.props.match.params.id) {
          const id = Number(this.props.match.params.id)

          axios.get('/bav').then((res) => {
            const bavData = res.data.data
            dispatch(updateBav(bavData))
            const selectedBav =
              bavData[
                bavData
                  .map((e) => {
                    return e.id
                  })
                  .indexOf(id)
              ]
            const bavID = selectedBav.status.id
            const bavStatusID = selectedBav.status.status.id

            axios.get(`/bavstatus?produktid=${selectedBav.produktid}`).then((response) => {
              axios.get('/document/list?benutzerid=' + selectedBav.id).then((listResponse) => {
                axios.get('/produkt?id=' + selectedBav.id).then((productResponse) => {
                  let insHelper = ''
                  if (productResponse.data.status == 'ok') {
                    insHelper =
                      productResponse.data.data[0].produktmantel.id == 2
                        ? 'Fondsgebundene Lebensversicherung'
                        : productResponse.data.data[0].produktmantel.id == 3
                        ? 'Klassische Lebensversicherung'
                        : ''
                  }
                  let documents = null
                  if (listResponse.data.status == 'ok') {
                    documents = [
                      listResponse.data.data.indexOf('willkommenspaket') > -1
                        ? {
                            name: 'Vertrag über die digitale Vermögensverwaltung',
                            link: '/document/willkommenspaket?benutzerid=' + selectedBav.id,
                          }
                        : null,
                      listResponse.data.data.indexOf('sepamandat') > -1
                        ? {
                            name: 'SEPA-Lastschriftmandat',
                            link: '/document/sepamandat?benutzerid=' + selectedBav.id,
                          }
                        : null,
                      listResponse.data.data.indexOf('depotvertrag') > -1
                        ? {
                            name: 'Kontoeröffnungsantrag',
                            link: '/document/depotvertrag?benutzerid=' + selectedBav.id,
                          }
                        : null,
                      listResponse.data.data.indexOf('vollmacht') > -1
                        ? {
                            name: 'Vollmacht für den Vermögensverwalter und für die Bank',
                            link: '/document/vollmacht?benutzerid=' + selectedBav.id,
                          }
                        : null,
                      listResponse.data.data.indexOf('freistellungsauftrag') > -1
                        ? {
                            name: 'Freistellungsauftrag für Kapitalerträge',
                            link: '/document/freistellungsauftrag?benutzerid=' + selectedBav.id,
                          }
                        : null,
                    ]
                  }

                  let allStatiHelp = {}
                  let status = {}
                  let selectedStatus = {}
                  const time = moment()
                  const sortedResponse = sortBy(response.data.data, (e) => {
                    return new moment(e.timestamp, 'DD.MM.YYYY kk:mm:ss')
                  })
                  const sortedActive = sortedResponse.filter((e) => {
                    if (e.status.id == 1) return e
                  })
                  const sortedMaternity = sortedResponse.filter((e) => {
                    if (e.status.id == 2) return e
                  })
                  const sortedLeave = sortedResponse.filter((e) => {
                    if (e.status.id == 3) return e
                  })
                  const sortedEnd = sortedResponse.filter((e) => {
                    if (e.status.id == 4) return e
                  })

                  if (bavStatusID == 1) {
                    allStatiHelp.active =
                      sortedResponse[
                        sortedResponse
                          .map((e) => {
                            return e.id
                          })
                          .indexOf(bavID)
                      ]

                    allStatiHelp.maternity =
                      sortedMaternity.length > 0 &&
                      new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedMaternity[sortedMaternity.length - 1]
                        : null
                    if (!!allStatiHelp.maternity) {
                      const index = sortedResponse
                        .map((e) => {
                          return e.id
                        })
                        .indexOf(allStatiHelp.maternity.id)
                      for (let i = 1; index + i < sortedResponse.length; i++) {
                        if (
                          sortedResponse[index + i].status.id == 1 &&
                          new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                            new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                          )
                        ) {
                          allStatiHelp.maternity.active = sortedResponse[index + i]
                          break
                        }
                      }
                    }

                    allStatiHelp.leave =
                      sortedLeave.length > 0 &&
                      new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedLeave[sortedLeave.length - 1]
                        : null

                    allStatiHelp.end =
                      sortedEnd.length > 0 &&
                      new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedEnd[sortedEnd.length - 1]
                        : null

                    selectedStatus = allStatiHelp.active
                  } else if (bavStatusID == 2) {
                    allStatiHelp.active =
                      sortedActive.length > 0 &&
                      new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedActive[sortedActive.length - 1]
                        : null

                    allStatiHelp.maternity =
                      sortedResponse[
                        sortedResponse
                          .map((e) => {
                            return e.id
                          })
                          .indexOf(bavID)
                      ]

                    if (!!allStatiHelp.maternity) {
                      const index = sortedResponse
                        .map((e) => {
                          return e.id
                        })
                        .indexOf(allStatiHelp.maternity.id)
                      for (let i = 1; index + i < sortedResponse.length; i++) {
                        if (
                          sortedResponse[index + i].status.id == 1 &&
                          new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                            new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                          )
                        ) {
                          allStatiHelp.maternity.active = sortedResponse[index + i]
                          break
                        }
                      }
                    }

                    allStatiHelp.leave =
                      sortedLeave.length > 0 &&
                      new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedLeave[sortedLeave.length - 1]
                        : null

                    allStatiHelp.end =
                      sortedEnd.length > 0 &&
                      new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedEnd[sortedEnd.length - 1]
                        : null

                    selectedStatus = allStatiHelp.maternity
                  } else if (bavStatusID == 3) {
                    allStatiHelp.active =
                      sortedActive.length > 0 &&
                      new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedActive[sortedActive.length - 1]
                        : null

                    allStatiHelp.maternity =
                      sortedMaternity.length > 0 &&
                      new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedMaternity[sortedMaternity.length - 1]
                        : null
                    if (!!allStatiHelp.maternity) {
                      const index = sortedResponse
                        .map((e) => {
                          return e.id
                        })
                        .indexOf(allStatiHelp.maternity.id)
                      for (let i = 1; index + i < sortedResponse.length; i++) {
                        if (
                          sortedResponse[index + i].status.id == 1 &&
                          new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                            new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                          )
                        ) {
                          allStatiHelp.maternity.active = sortedResponse[index + i]
                          break
                        }
                      }
                    }

                    allStatiHelp.leave =
                      sortedResponse[
                        sortedResponse
                          .map((e) => {
                            return e.id
                          })
                          .indexOf(bavID)
                      ]

                    allStatiHelp.end =
                      sortedEnd.length > 0 &&
                      new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedEnd[sortedEnd.length - 1]
                        : null

                    selectedStatus = allStatiHelp.leave
                  } else if (bavStatusID == 4) {
                    allStatiHelp.active =
                      sortedActive.length > 0 &&
                      new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedActive[sortedActive.length - 1]
                        : null

                    allStatiHelp.maternity =
                      sortedMaternity.length > 0 &&
                      new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedMaternity[sortedMaternity.length - 1]
                        : null
                    if (!!allStatiHelp.maternity) {
                      const index = sortedResponse
                        .map((e) => {
                          return e.id
                        })
                        .indexOf(allStatiHelp.maternity.id)
                      for (let i = 1; index + i < sortedResponse.length; i++) {
                        if (
                          sortedResponse[index + i].status.id == 1 &&
                          new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                            new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                          )
                        ) {
                          allStatiHelp.maternity.active = sortedResponse[index + i]
                          break
                        }
                      }
                    }

                    allStatiHelp.leave =
                      sortedLeave.length > 0 &&
                      new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                        ? sortedLeave[sortedLeave.length - 1]
                        : null

                    allStatiHelp.end =
                      sortedResponse[
                        sortedResponse
                          .map((e) => {
                            return e.id
                          })
                          .indexOf(bavID)
                      ]

                    selectedStatus = allStatiHelp.end
                  }

                  const selectedHelp = {
                    ...selectedBav,
                    fullname: selectedBav.vorname + ' ' + selectedBav.nachname,
                    status: bavStatusID,
                    polizzennummer: productResponse.data.data[0].depot.nummer,
                  }

                  if (!selectedStatus) {
                    selectedStatus = selectedBav.status
                  }

                  const statusHelp = {
                    approved: selectedStatus.approved,
                    value: selectedStatus.status.id,
                    startDate: selectedStatus.datum,
                    startID: selectedStatus.id,
                    endDate: !!selectedStatus.active ? selectedStatus.active.datum : null,
                    endID: !!selectedStatus.active ? selectedStatus.active.id : null,
                    month: moment(selectedStatus.datum, 'DD.MM.YYYY').month() + 1,
                    year: moment(selectedStatus.datum, 'DD.MM.YYYY').year(),
                  }

                  this.setState({
                    selected: selectedHelp,
                    status: statusHelp,
                    allStati: allStatiHelp,
                    isEditing: false,
                    editFirstname: selectedHelp.vorname,
                    editLastname: selectedHelp.nachname,
                    editAddress: selectedHelp.strasse,
                    editHouseNumber: selectedHelp.houseNumber,
                    editStaircase: selectedHelp.staircase,
                    editDoorNumber: selectedHelp.doorNumber,
                    editPlz: selectedHelp.plz,
                    editOrt: selectedHelp.ort,
                    editEmail: selectedHelp.email,
                    editBirthday: selectedHelp.geburtsDatum,
                    editPersonalNummer: selectedHelp.personalnummer,
                    disableButton: true,
                    disableApprove: selectedStatus.approved,
                    allDocuments: !!documents ? documents.filter(Boolean) : null,
                    insuranceType: insHelper,
                  })
                })
              })
            })
          })
        } else {
          axios.get('/bav').then((bavData) => {
            dispatch(updateBav(bavData.data.data))
            this.setState({
              selected: null,
              status: null,
              editError: '',
              editSuccess: '',
              statusError: '',
              statusSuccess: '',
              disableButton: true,
              disableApprove: true,
              minYear: moment().year(),
            })
          })
        }
      }
    }
  }

  render() {
    const { role, history } = this.props
    const {
      activeChecked,
      inactiveChecked,
      searchTerm,
      companyFilter,
      selected,
      status,
      allStati,
      statusError,
      statusSuccess,
      data,
      isEditing,
      editError,
      editSuccess,
      editFirstname,
      editLastname,
      editAddress,
      editHouseNumber,
      editStaircase,
      editDoorNumber,
      editPlz,
      editOrt,
      editPersonalNummer,
      minYear,
      disableButton,
      disableApprove,
      allDocuments,
      insuranceType,
    } = this.state

    const years = []

    for (let i = minYear; i < minYear + 10; i++) {
      years.push(i)
    }

    const adminYears = []

    for (let i = moment().year() - 1; i < moment().year() - 1 + 10; i++) {
      adminYears.push(i)
    }

    const columns =
      role === 2 || role === 9
        ? [
            {
              Header: 'Name',
              accessor: 'fullname',
              headerClassName: 'sortable',
              sortable: true,
              width: 315.38,
            },
            {
              Header: 'Unternehmen',
              accessor: 'unternehmen',
              width: 315.38,
            },
            {
              Header: 'Status',
              accessor: 'status.status.id',
              Cell: (row) => (
                <div className="rt-td" role="gridcell" style={{ flex: '100 0 auto', width: '100px' }}>
                  {row.value == 1 ? 'Aktiv' : 'Inaktiv'}
                </div>
              ),
              width: 315.38,
            },
          ]
        : [
            {
              Header: 'Name',
              accessor: 'fullname',
              headerClassName: 'sortable',
              sortable: true,
            },
            {
              Header: 'Personalnummer',
              accessor: 'personalnummer',
            },
            {
              Header: 'Status',
              accessor: 'status.status.id',
              Cell: (row) => (
                <div className="rt-td" role="gridcell" style={{ flex: '100 0 auto', width: '100px' }}>
                  {row.value == 1 ? 'Aktiv' : 'Inaktiv'}
                </div>
              ),
            },
          ]

    const filteredData = data.filter((bav) => {
      return (
        (bav.fullname.toLowerCase().indexOf(searchTerm) > -1 ||
          (!!bav.personalnummer && bav.personalnummer.toLowerCase().indexOf(searchTerm) > -1)) &&
        ((role !== 2 && role !== 9) || !companyFilter || companyFilter === 'all' || bav.unternehmen == companyFilter) &&
        ((activeChecked && bav.status.status.id == 1) || (inactiveChecked && bav.status.status.id != 1))
      )
    })

    return (
      <div id="Employees">
        <h2>Mitarbeiter</h2>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <TextField
                  id="search"
                  label="Suche"
                  style={{ width: '250px', margin: '10px 20px 10px 0' }}
                  onChange={(event: any) => this.setState({ searchTerm: event.target.value })}
                  className="searchBar input"
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="activeChecked"
                      color="primary"
                      checked={activeChecked}
                      onChange={(event) => this.setState({ activeChecked: event.target.checked })}
                    />
                  }
                  label="Aktiv"
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="inactiveChecked"
                      color="primary"
                      checked={inactiveChecked}
                      onChange={(event) => this.setState({ inactiveChecked: event.target.checked })}
                    />
                  }
                  label="Inaktiv"
                />
              </td>
            </tr>

            <tr>
              {(role === 2 || role === 9) && (
                <td>
                  <FormControl style={{ width: '250px', margin: '10px 20px 20px 0' }}>
                    <InputLabel htmlFor="age-simple">Unternehmen</InputLabel>
                    <Select
                      value={!!companyFilter ? companyFilter : ''}
                      onChange={(event) =>
                        history.push(
                          '/unternehmensportal/employees' +
                            (event.target.value ? '/' + event.target.value : '') +
                            (!!this.props.match.params.id ? '/' + this.props.match.params.id : '/'),
                        )
                      }
                    >
                      <MenuItem value=""></MenuItem>
                      {data
                        .map((bav) => bav.unternehmen)
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .map((company, index) => (
                          <MenuItem key={index} value={company}>
                            {company}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </td>
              )}

              <td colSpan="2">
                <div style={{ margin: '18px 0 10px 0' }}>Anzahl Mitarbeiter: {filteredData.length}</div>
              </td>
            </tr>
          </tbody>
        </table>

        <ReactTable
          data={filteredData}
          noDataText="Keine Daten vorhanden."
          showPagination={false}
          pageSize={filteredData.length > 9 ? filteredData.length : 9}
          columns={columns}
          className="employeesTable -highlight"
          style={role === 2 || role === 9 ? { height: 'calc(100% - 189px)' } : null}
          getTdProps={(state, row, column, instance) => {
            return {
              onClick: () =>
                history.push(
                  '/unternehmensportal/employees/' + (!!companyFilter ? companyFilter + '/' : 'all/') + row.original.id,
                ),
            }
          }}
        />

        <Modal
          isOpen={!!selected}
          onRequestClose={() => history.push('/unternehmensportal/employees')}
          className="modalContent"
          overlayClassName="modalOverlay"
          portalClassName="EmployeeDetail"
        >
          {!!selected && (
            <div>
              <div className="topHalf">
                <h1>{selected.fullname}</h1>
                <img
                  src="/assets/images/close.png"
                  className="closeButton"
                  onClick={() => history.push('/unternehmensportal/employees')}
                />
                <h3>Status</h3>
                {statusError ? <div className="error">{statusError}</div> : null}
                <div className="flexBox" style={{ height: '170px' }}>
                  <div className="leftBox">
                    <RadioGroup
                      name="status"
                      value={!!status.value ? status.value.toString() : '1'}
                      onChange={(event: any) => {
                        const val = parseInt(event.target.value)
                        const statusHelper =
                          val == 1
                            ? allStati.active
                            : val == 2
                            ? allStati.maternity
                            : val == 3
                            ? allStati.leave
                            : allStati.end

                        const helper = {
                          approved: !!statusHelper ? statusHelper.approved : false,
                          value: val,
                          startDate: !!statusHelper ? statusHelper.datum : null,
                          startID: !!statusHelper ? statusHelper.id : 0,
                          endDate: !!statusHelper && !!statusHelper.active ? statusHelper.active.datum : null,
                          endID: !!statusHelper && !!statusHelper.active ? statusHelper.active.id : 0,
                          month: !!statusHelper ? moment(statusHelper.datum, 'DD.MM.YYYY').month() + 1 : '',
                          year: !!statusHelper ? moment(statusHelper.datum, 'DD.MM.YYYY').year() : '',
                        }
                        this.setState(
                          {
                            status: helper,
                            statusError: '',
                            statusSuccess: '',
                            disableButton: true,
                            disableApprove: true,
                          },
                          this.determineSave,
                        )
                      }}
                    >
                      <FormControlLabel
                        className="status__radioItem"
                        value="1"
                        control={<Radio style={{ height: '35px' }} color="primary" />}
                        label={
                          selected.status != 1 ? (
                            <span>
                              Aktivieren
                              <span className="tooltipSymbol">
                                <img src="/assets/images/info icon.png" />
                                <div className="tooltip">
                                  Der monatliche Beitrag für den Mitarbeiter wird aktuell wegen Karenz oder sonstigen
                                  Gründen nicht gezahlt. Sie können die Zahlung hier wieder aufnehmen.
                                </div>
                              </span>
                            </span>
                          ) : (
                            <span>
                              Aktiv
                              <span className="tooltipSymbol">
                                <img src="/assets/images/info icon.png" />
                                <div className="tooltip">
                                  Der monatliche Beitrag für den Mitarbeiter wird aktuell planmäßig gezahlt. Um den
                                  Status des Mitarbeiters zu ändern, wählen Sie bitte eine nachfolgende Option aus.
                                </div>
                              </span>
                            </span>
                          )
                        }
                      />
                      <FormControlLabel
                        className="status__radioItem"
                        value="2"
                        control={<Radio style={{ height: '35px' }} color="primary" />}
                        label={
                          <span>
                            Karenz
                            <span className="tooltipSymbol">
                              <img src="/assets/images/info icon.png" />
                              {selected.status != 2 ? (
                                <div className="tooltip">
                                  Sie können hier die Karenz des Mitarbeiters eintragen. Die Zahlung wird dann
                                  eingestellt. Dafür genügt die Angabe des letzten Arbeitstags des Mitarbeiters. Die
                                  Angabe des Karenz Endes ist nicht zwingend erforderlich.
                                </div>
                              ) : (
                                <div className="tooltip">
                                  Der monatliche Beitrag für den Mitarbeiter wird aktuell wegen Karenz nicht gezahlt.
                                  Sie können die Zahlung mit der Option "Aktivieren" wieder aufnehmen.
                                </div>
                              )}
                            </span>
                          </span>
                        }
                      />
                      <FormControlLabel
                        className="status__radioItem"
                        value="3"
                        control={<Radio style={{ height: '35px' }} color="primary" />}
                        label={
                          <span>
                            Verlässt Unternehmen
                            <span className="tooltipSymbol">
                              <img src="/assets/images/info icon.png" />
                              <div className="tooltip">
                                Sie können hier vermerken, wenn feststeht, dass der Mitarbeiter das Unternehmen
                                verlässt. Die Zahlung wird dann eingestellt.
                              </div>
                            </span>
                          </span>
                        }
                      />
                      <FormControlLabel
                        className="status__radioItem"
                        value="4"
                        control={<Radio style={{ height: '35px' }} color="primary" />}
                        label={
                          selected.status != 4 ? (
                            <span>
                              Zahlung einstellen
                              <span className="tooltipSymbol">
                                <img src="/assets/images/info icon.png" />
                                <div className="tooltip">
                                  Wenn aus einem beliebigen Grund die Zahlung eingestellt werden soll, können Sie dies
                                  hier vermerken. Um die Zahlung wieder zu beginnen, wählen Sie den Option "Aktivieren"
                                  aus.
                                </div>
                              </span>
                            </span>
                          ) : (
                            <span>
                              Zahlung eingestellt
                              <span className="tooltipSymbol">
                                <img src="/assets/images/info icon.png" />
                                <div className="tooltip">
                                  Der monatliche Beitrag für den Mitarbeiter wird aktuell nicht gezahlt. Sie können die
                                  Zahlung mit der Option "Aktivieren" wieder aufnehmen.
                                </div>
                              </span>
                            </span>
                          )
                        }
                      />
                    </RadioGroup>
                  </div>

                  {status.value === 1 && (
                    <div className="rightBox dateBox">
                      <div className="grayLabel">Erster Zahl-Tag</div>
                      <DatePicker
                        id="validFrom"
                        label="Gültig ab"
                        cancelLabel="Abbrechen"
                        format="dd.MM.yyyy"
                        value={!!status.startDate ? moment(status.startDate, 'DD.MM.YYYY').toDate() : null}
                        minDate={isSuperAdmin(role) ? moment().subtract(1, 'years') : moment().add(1, 'days')}
                        autoOk
                        onChange={(value) => {
                          this.setState(
                            {
                              statusSuccess: '',
                              status: {
                                ...status,
                                startDate: date.format(value, 'dd.MM.yyyy'),
                              },
                            },
                            this.determineSave,
                          )
                        }}
                      />
                    </div>
                  )}

                  {status.value === 2 && (
                    <div className="rightBox dateBox">
                      <div className="grayLabel">Letzter Arbeitstag</div>
                      <DatePicker
                        id="validFrom"
                        label="Gültig ab"
                        cancelLabel="Abbrechen"
                        format="dd.MM.yyyy"
                        value={!!status.startDate ? moment(status.startDate, 'DD.MM.YYYY').toDate() : null}
                        minDate={isSuperAdmin(role) ? moment().subtract(1, 'years') : moment().add(1, 'days')}
                        autoOk
                        onChange={(value) => {
                          this.setState(
                            {
                              statusSuccess: '',
                              status: {
                                ...status,
                                startDate: date.format(value, 'dd.MM.yyyy'),
                                endDate:
                                  !status.endDate ||
                                  moment(value, 'DD.MM.YYYY').isBefore(moment(status.endDate, 'DD.MM.YYYY'))
                                    ? status.endDate
                                    : date.format(value, 'dd.MM.yyyy'),
                              },
                            },
                            this.determineSave,
                          )
                        }}
                      />
                      <div className="grayLabel">Karenz Ende</div>
                      <DatePicker
                        id="validFrom"
                        label="Gültig ab"
                        cancelLabel="Abbrechen"
                        emptyLabel="Noch nicht bekannt"
                        disabled={true}
                        format="dd.MM.yyyy"
                        value={!!status.endDate ? moment(status.endDate, 'DD.MM.YYYY').toDate() : null}
                        minDate={isSuperAdmin(role) ? moment().subtract(1, 'years') : moment().add(1, 'days')}
                        autoOk
                        onChange={(value) => {
                          this.setState(
                            {
                              statusSuccess: '',
                              status: {
                                ...status,
                                endDate: date.format(value, 'dd.MM.yyyy'),
                              },
                            },
                            this.determineSave,
                          )
                        }}
                      />
                    </div>
                  )}

                  {status.value === 3 && (
                    <div className="rightBox dateBox">
                      <div className="grayLabel">Letzter Arbeitstag</div>
                      <DatePicker
                        id="validFrom"
                        label="Gültig ab"
                        cancelLabel="Abbrechen"
                        format="dd.MM.yyyy"
                        value={!!status.startDate ? moment(status.startDate, 'DD.MM.YYYY').toDate() : null}
                        minDate={isSuperAdmin(role) ? moment().subtract(1, 'years') : moment().add(1, 'days')}
                        autoOk
                        onChange={(value) => {
                          this.setState(
                            {
                              statusSuccess: '',
                              status: {
                                ...status,
                                startDate: date.format(value, 'dd.MM.yyyy'),
                              },
                            },
                            this.determineSave,
                          )
                        }}
                      />
                    </div>
                  )}

                  {status.value === 4 && (
                    <div className="rightBox dateBox">
                      <div className="grayLabel">Letzter Zahl-Tag</div>
                      <DatePicker
                        id="validFrom"
                        label="Gültig ab"
                        cancelLabel="Abbrechen"
                        format="dd.MM.yyyy"
                        value={!!status.startDate ? moment(status.startDate, 'DD.MM.YYYY').toDate() : null}
                        minDate={isSuperAdmin(role) ? moment().subtract(1, 'years') : moment().add(1, 'days')}
                        autoOk
                        onChange={(value) => {
                          this.setState(
                            {
                              statusSuccess: '',
                              status: {
                                ...status,
                                startDate: date.format(value, 'dd.MM.yyyy'),
                              },
                            },
                            this.determineSave,
                          )
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="row" style={{ margin: '20px 0' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="button primary outlined"
                    style={{ minWidth: '150px', maxHeight: '37px', marginRight: '10px' }}
                    disabled={disableButton}
                    onClick={() => this.handleSaveSubmit(false)}
                  >
                    SPEICHERN
                  </Button>

                  {isSuperAdmin(role) && status.startID != 0 && !disableApprove && (
                    <Button
                      variant="outlined"
                      color="primary"
                      className="button primary outlined"
                      style={{ minWidth: '150px', maxHeight: '37px', marginRight: '10px' }}
                      onClick={() => this.handleSaveSubmit(true)}
                    >
                      BESTÄTIGEN
                    </Button>
                  )}

                  {isSuperAdmin(role) && status.startID != 0 && !disableApprove && (
                    <Button
                      variant="outlined"
                      color="primary"
                      className="button primary outlined"
                      style={{ minWidth: '150px', maxHeight: '37px', marginRight: '10px' }}
                      onClick={() => this.handleDelete()}
                    >
                      ABLEHNEN
                    </Button>
                  )}
                  {!!statusSuccess ? <div className="success">{statusSuccess}</div> : null}
                </div>
              </div>

              <div className="lowerHalf flexBox">
                {!isEditing ? (
                  <div className="leftBox">
                    <h3>
                      Persönliche Daten
                      <img
                        src="/assets/images/ic_edit.png"
                        onClick={() => this.setState({ isEditing: true, editSuccess: '' })}
                      />
                    </h3>
                    <table>
                      <tbody>
                        <tr>
                          <td className="grayLabel">Name:</td>
                          <td>{selected.fullname}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Straße:</td>
                          <td>{selected.strasse}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Hausnummer:</td>
                          <td>{selected.houseNumber}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Stiege:</td>
                          <td>{selected.staircase}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Tür:</td>
                          <td>{selected.doorNumber}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">PLZ:</td>
                          <td>{selected.plz}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Ort:</td>
                          <td>{selected.ort}</td>
                        </tr>

                        <tr>
                          <td className="grayLabel">E-Mail:</td>
                          <td>{selected.email}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Geburtsdatum:</td>
                          <td>{selected.geburtsDatum}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Personalnummer:</td>
                          <td>{selected.personalnummer}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="leftBox">
                    <h3>
                      Persönliche Daten
                      <img src="/assets/images/close.png" onClick={() => this.setState({ isEditing: false })} />
                    </h3>
                    <table>
                      <tbody>
                        <tr>
                          <td className="grayLabel">Name:</td>
                          <td>
                            <TextField
                              id="firstname"
                              style={{ width: 'calc(50% - 3px)' }}
                              value={editFirstname}
                              onChange={(event: any) => this.setState({ editFirstname: event.target.value })}
                              className="input"
                            />
                            <TextField
                              id="lastname"
                              style={{ width: 'calc(50% - 3px)', marginLeft: '6px' }}
                              value={editLastname}
                              onChange={(event: any) => this.setState({ editLastname: event.target.value })}
                              className="input"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Straße:</td>
                          <td>
                            <TextField
                              id="address"
                              style={{ width: '100%' }}
                              value={editAddress}
                              onChange={(event: any) => this.setState({ editAddress: event.target.value })}
                              className="input"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Hausnummer:</td>
                          <td>
                            <TextField
                              id="houseNumber"
                              style={{ width: 'calc(50% - 3px)' }}
                              value={editHouseNumber}
                              onChange={(event: any) => this.setState({ editHouseNumber: event.target.value })}
                              className="input"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Stiege:</td>
                        <td>
                          <TextField
                              id="staircase"
                              style={{ width: 'calc(50% - 3px)' }}
                              value={editStaircase}
                              onChange={(event: any) => this.setState({ editStaircase: event.target.value })}
                              className="input"
                          />
                        </td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Tür:</td>
                        <td>
                          <TextField
                              id="doorNumber"
                              style={{ width: 'calc(50% - 3px)' }}
                              value={editDoorNumber}
                              onChange={(event: any) => this.setState({ editDoorNumber: event.target.value })}
                              className="input"
                          />
                          </td>
                        </tr>
                        <tr>
                          <td className="grayLabel">PLZ:</td>
                        <td>
                          <TextField
                              id="plz"
                              style={{ width: 'calc(50% - 3px)' }}
                              value={editPlz.toString()}
                              onChange={(event: any) => this.setState({ editPlz: parseInt(event.target.value) })}
                              className="input"
                          />
                        </td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Ort:</td>
                          <td>
                            <TextField
                              id="ort"
                              style={{ width: '100%' }}
                              value={editOrt}
                              onChange={(event: any) => this.setState({ editOrt: event.target.value })}
                              className="input"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="grayLabel">E-Mail:</td>
                          <td>{selected.email}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Geburtsdatum:</td>
                          <td>{selected.geburtsDatum}</td>
                        </tr>
                        <tr>
                          <td className="grayLabel">Personalnummer:</td>
                          <td>
                            <TextField
                              id="personalNummer"
                              style={{ width: '100%' }}
                              value={editPersonalNummer}
                              onChange={(event: any) => this.setState({ editPersonalNummer: event.target.value })}
                              className="input"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="button primary outlined"
                      style={{ width: '150px', margin: '20px 0 0 0' }}
                      onClick={this.handleEditSubmit}
                    >
                      SPEICHERN
                    </Button>
                  </div>
                )}

                <div className="rightBox">
                  <h3>Vertragsdaten</h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="grayLabel">Versicherung:</td>
                        <td>{selected.partner.name}</td>
                      </tr>
                      <tr>
                        <td className="grayLabel">Produkt:</td>
                        <td>{insuranceType}</td>
                      </tr>
                      <tr>
                        <td className="grayLabel">Polizzennummer:</td>
                        <td>{selected.polizzennummer}</td>
                      </tr>
                      <tr>
                        <td className="grayLabel">Vertragsbeginn:</td>
                        <td>{selected.start}</td>
                      </tr>
                      <tr>
                        <td className="grayLabel">Aktueller Wert:</td>
                        <td className="bold">{selected.gesamtwert ? selected.gesamtwert + ' €' : null}</td>
                      </tr>
                      <tr>
                        <td className="grayLabel">Variante:</td>
                        <td>{selected.sammelkonto === 'true' ? 'Gehaltsumwandlung' : 'Gehaltserhöhung'}</td>
                      </tr>
                    </tbody>
                  </table>

                  {!!allDocuments && allDocuments.length > 0 && <h3>Dokumente</h3>}
                  {!!allDocuments &&
                    allDocuments.map((doc, index) => {
                      return (
                        <div key={index} className="documentLink">
                          <a href={doc.link}>
                            <img src="/assets/images/pdf_icon_small.png" />
                            {doc.name}
                          </a>
                        </div>
                      )
                    })}
                </div>
              </div>

              <div style={{ padding: '0 40px 40px 40px' }}>
                {editSuccess && <div className="success">{editSuccess}</div>}
                {editError && <div className="error">{editError}</div>}
              </div>
            </div>
          )}
        </Modal>
      </div>
    )
  }

  /// Event Handlers
  determineSave(): void {
    const { allStati, status } = this.state

    if (status.value === 1 && allStati.active && !!status.startDate) {
      if (!allStati.active.approved) {
        this.setState({ disableButton: true, disableApprove: false })
      } else {
        this.setState({ disableButton: true, disableApprove: true })
      }
    } else {
      this.setState({ disableButton: false, disableApprove: false })
    }

    if (status.value === 2 && allStati.maternity && !!status.startDate) {
      if (
        status.startDate != allStati.maternity.datum ||
        (allStati.maternity.active && allStati.maternity.active.datum != status.endDate) ||
        (!allStati.maternity.active && !!status.endDate)
      ) {
        this.setState({ disableButton: false, disableApprove: false })
      } else if (!allStati.maternity.approved) {
        this.setState({ disableButton: true, disableApprove: false })
      } else {
        this.setState({ disableButton: true, disableApprove: true })
      }
    } else {
      this.setState({ disableButton: false, disableApprove: false })
    }

    if (status.value === 3 && allStati.leave && !!status.startDate) {
      if (status.startDate != allStati.leave.datum) {
        this.setState({ disableButton: false, disableApprove: false })
      } else if (!allStati.leave.approved) {
        this.setState({ disableButton: true, disableApprove: false })
      } else {
        this.setState({ disableButton: true, disableApprove: true })
      }
    } else {
      this.setState({ disableButton: false, disableApprove: false })
    }

    if (status.value === 4 && allStati.end && !!status.endDate) {
      if (!allStati.end.approved) {
        this.setState({ disableButton: true, disableApprove: false })
      } else {
        this.setState({ disableButton: true, disableApprove: true })
      }
    } else {
      this.setState({ disableButton: false, disableApprove: false })
    }
  }

  handleEditSubmit(): void {
    const {
      editFirstname,
      editLastname,
      editAddress,
      editHouseNumber,
      editStaircase,
      editDoorNumber,
      editPlz,
      editOrt,
      editEmail,
      editBirthday,
      editPersonalNummer,
      selected,
    } = this.state

    const { dispatch } = this.props

    // quickfix: disable button when request inflight
    this.setState((prevState) => ({ ...prevState, disableButton: true }))

    updateEmployee(
      selected.id,
      editFirstname,
      editLastname,
      editAddress,
        editHouseNumber,
        editStaircase,
        editDoorNumber,
      editPlz,
      editOrt,
      editEmail,
      editBirthday,
      editPersonalNummer,
      (data: any) => {
        if (data.success) {
          dispatch(updateBav(data.bavData))
          this.setState({
            selected: {
              ...selected,
              vorname: editFirstname,
              nachname: editLastname,
              fullname: editFirstname + ' ' + editLastname,
              strasse: editAddress,
              houseNumber: editHouseNumber,
              staircase: editStaircase,
              doorNumber: editDoorNumber,
              ort: editOrt,
              plz: editPlz,
              email: editEmail,
              geburtsDatum: editBirthday,
              personalnummer: editPersonalNummer,
            },
            isEditing: false,
            editSuccess: 'Die Eingabe wurde erfolgreich gespeichert.',
          })
        } else {
          this.setState({ editError: data.error })
        }
      },
    )
  }

  handleSaveSubmit(isApproved: boolean = false): void {
    const { selected, status, allStati } = this.state
    const { role, dispatch } = this.props

    // quickfix: disable button when request inflight
    this.setState((prevState) => ({ ...prevState, disableButton: true }))

    if (!!status.startDate) {
      updateEmployeeStatus(
        selected.produktid,
        status.startID,
        status.startDate,
        status.value,
        isSuperAdmin(role) && isApproved,
        (data1: any) => {
          if (data1.success) {
            const newStatus = {
              approved: data1.status.approved,
              value: data1.status.status.id,
              startID: data1.status.id,
              startDate: data1.status.datum,
              endID: 0,
              endDate: null,
              month: moment(data1.status.datum, 'DD.MM.YYYY').month() + 1,
              year: moment(data1.status.datum, 'DD.MM.YYYY').year(),
            }

            const replaceAllStati = data1.status

            if (status.value === 2 && !!status.endDate) {
              updateEmployeeStatus(
                selected.produktid,
                status.endID,
                status.endDate,
                1,
                isSuperAdmin(role) && isApproved,
                (data2: any) => {
                  if (data2.success) {
                    dispatch(updateBav(data2.bavData))

                    if (
                      new moment(data2.status.datum, 'DD.MM.YYYY').isAfter(
                        new moment(replaceAllStati.datum, 'DD.MM.YYYY'),
                      )
                    ) {
                      newStatus.endID = data2.status.id
                      newStatus.endDate = data2.status.datum
                      replaceAllStati.active = data2.status
                    } else {
                      newStatus.endID = 0
                      newStatus.endDate = null
                    }
                    this.setState({
                      statusError: '',
                      statusSuccess: isSuperAdmin(role)
                        ? 'Die Eingabe wurde erfolgreich gespeichert'
                        : 'Die Eingabe wurde an die Versicherung kommuniziert.',
                      disableButton: true,
                      disableApprove: newStatus.approved,
                      selected: {
                        ...selected,
                        status: data1.status.status.id,
                      },
                      status: newStatus,
                      allStati: {
                        ...allStati,
                        active: !allStati.active || status.endID == allStati.active.id ? data2.status : allStati.active,
                        maternity: replaceAllStati,
                      },
                    })
                  } else {
                    this.setState({ statusError: data2.error })
                  }
                },
              )
            } else {
              dispatch(updateBav(data1.bavData))

              this.setState({
                statusError: '',
                statusSuccess: isSuperAdmin(role)
                  ? 'Die Eingabe wurde erfolgreich gespeichert'
                  : 'Die Eingabe wurde an die Versicherung kommuniziert.',
                disableButton: true,
                disableApprove: newStatus.approved,
                selected: {
                  ...selected,
                  status: newStatus,
                },
                status: newStatus,
                allStati: {
                  active: status.value == 1 ? replaceAllStati : allStati.active,
                  maternity:
                    status.value == 2
                      ? replaceAllStati
                      : status.value == 1 &&
                        !!allStati.maternity &&
                        (!allStati.maternity.active || status.startID == allStati.maternity.active.id) &&
                        new moment(replaceAllStati.datum, 'DD.MM.YYYY').isAfter(
                          new moment(allStati.maternity.datum, 'DD.MM.YYYY'),
                        )
                      ? {
                          ...allStati.maternity,
                          active: replaceAllStati,
                        }
                      : allStati.maternity,
                  leave: status.value == 3 ? replaceAllStati : allStati.leave,
                  end: status.value == 4 ? replaceAllStati : allStati.end,
                },
              })
            }
          } else {
            this.setState({ statusError: data1.error })
          }
        },
      )
    } else {
      this.setState({ statusError: 'Alle Felder müssen befüllt sein' })
    }
  }

  handleDelete(): void {
    const { selected, status } = this.state
    const { role, dispatch } = this.props

    if (!!status.startID) {
      deleteEmployeeStatus(selected.produktid, status.startID, (data1) => {
        if (!!status.endID) {
          deleteEmployeeStatus(selected.produktid, status.endID, (data2) => {
            if (data2.success) {
              const bavData = data2.bavData
              dispatch(updateBav(data2.bavData))

              const selectedBav = bavData[bavData.map((e) => e.id).indexOf(selected.id)]
              const bavID = selectedBav.status.id
              const bavStatusID = selectedBav.status.status.id

              axios.get(`/bavstatus?produktid=${selectedBav.produktid}`).then((response) => {
                let allStatiHelp = {}
                let selectedStatus = {}
                const time = moment()
                const sortedResponse = sortBy(response.data.data, (e) => {
                  return new moment(e.timestamp, 'DD.MM.YYYY kk:mm:ss')
                })
                const sortedActive = sortedResponse.filter((e) => {
                  if (e.status.id == 1) return e
                })
                const sortedMaternity = sortedResponse.filter((e) => {
                  if (e.status.id == 2) return e
                })
                const sortedLeave = sortedResponse.filter((e) => {
                  if (e.status.id == 3) return e
                })
                const sortedEnd = sortedResponse.filter((e) => {
                  if (e.status.id == 4) return e
                })

                if (bavStatusID == 1) {
                  allStatiHelp.active = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

                  allStatiHelp.maternity =
                    sortedMaternity.length > 0 &&
                    new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedMaternity[sortedMaternity.length - 1]
                      : null
                  if (!!allStatiHelp.maternity) {
                    const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                    for (let i = 1; index + i < sortedResponse.length; i++) {
                      if (
                        sortedResponse[index + i].status.id == 1 &&
                        new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                          new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                        )
                      ) {
                        allStatiHelp.maternity.active = sortedResponse[index + i]
                        break
                      }
                    }
                  }

                  allStatiHelp.leave =
                    sortedLeave.length > 0 &&
                    new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedLeave[sortedLeave.length - 1]
                      : null

                  allStatiHelp.end =
                    sortedEnd.length > 0 &&
                    new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedEnd[sortedEnd.length - 1]
                      : null

                  selectedStatus = allStatiHelp.active
                } else if (bavStatusID == 2) {
                  allStatiHelp.active =
                    sortedActive.length > 0 &&
                    new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedActive[sortedActive.length - 1]
                      : null

                  allStatiHelp.maternity =
                    sortedResponse[
                      sortedResponse
                        .map((e) => {
                          return e.id
                        })
                        .indexOf(bavID)
                    ]

                  if (!!allStatiHelp.maternity) {
                    const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                    for (let i = 1; index + i < sortedResponse.length; i++) {
                      if (
                        sortedResponse[index + i].status.id == 1 &&
                        new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                          new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                        )
                      ) {
                        allStatiHelp.maternity.active = sortedResponse[index + i]
                        break
                      }
                    }
                  }

                  allStatiHelp.leave =
                    sortedLeave.length > 0 &&
                    new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedLeave[sortedLeave.length - 1]
                      : null

                  allStatiHelp.end =
                    sortedEnd.length > 0 &&
                    new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedEnd[sortedEnd.length - 1]
                      : null

                  selectedStatus = allStatiHelp.maternity
                } else if (bavStatusID == 3) {
                  allStatiHelp.active =
                    sortedActive.length > 0 &&
                    new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedActive[sortedActive.length - 1]
                      : null

                  allStatiHelp.maternity =
                    sortedMaternity.length > 0 &&
                    new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedMaternity[sortedMaternity.length - 1]
                      : null
                  if (!!allStatiHelp.maternity) {
                    const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                    for (let i = 1; index + i < sortedResponse.length; i++) {
                      if (
                        sortedResponse[index + i].status.id == 1 &&
                        new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                          new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                        )
                      ) {
                        allStatiHelp.maternity.active = sortedResponse[index + i]
                        break
                      }
                    }
                  }

                  allStatiHelp.leave =
                    sortedResponse[
                      sortedResponse
                        .map((e) => {
                          return e.id
                        })
                        .indexOf(bavID)
                    ]

                  allStatiHelp.end =
                    sortedEnd.length > 0 &&
                    new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedEnd[sortedEnd.length - 1]
                      : null

                  selectedStatus = allStatiHelp.leave
                } else if (bavStatusID == 4) {
                  allStatiHelp.active =
                    sortedActive.length > 0 &&
                    new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedActive[sortedActive.length - 1]
                      : null

                  allStatiHelp.maternity =
                    sortedMaternity.length > 0 &&
                    new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedMaternity[sortedMaternity.length - 1]
                      : null
                  if (!!allStatiHelp.maternity) {
                    const index = sortedResponse.map((e) => e.id).indexOf(allStatiHelp.maternity.id)
                    for (let i = 1; index + i < sortedResponse.length; i++) {
                      if (
                        sortedResponse[index + i].status.id == 1 &&
                        new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                          new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                        )
                      ) {
                        allStatiHelp.maternity.active = sortedResponse[index + i]
                        break
                      }
                    }
                  }

                  allStatiHelp.leave =
                    sortedLeave.length > 0 &&
                    new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                      ? sortedLeave[sortedLeave.length - 1]
                      : null

                  allStatiHelp.end =
                    sortedResponse[
                      sortedResponse
                        .map((e) => {
                          return e.id
                        })
                        .indexOf(bavID)
                    ]

                  selectedStatus = allStatiHelp.end
                }

                const selectedHelp = {
                  ...selectedBav,
                  fullname: selectedBav.vorname + ' ' + selectedBav.nachname,
                  status: bavStatusID,
                }

                const statusHelp = {
                  approved: selectedStatus.approved,
                  value: selectedStatus.status.id,
                  startDate: selectedStatus.datum,
                  startID: selectedStatus.id,
                  endDate: !!selectedStatus.active ? selectedStatus.active.datum : null,
                  endID: !!selectedStatus.active ? selectedStatus.active.id : null,
                  month: moment(selectedStatus.datum, 'DD.MM.YYYY').month() + 1,
                  year: moment(selectedStatus.datum, 'DD.MM.YYYY').year(),
                }

                this.setState({
                  selected: selectedHelp,
                  status: statusHelp,
                  allStati: allStatiHelp,
                  statusError: '',
                  statusSuccess: isSuperAdmin(role)
                    ? 'Die Eingabe wurde erfolgreich gespeichert'
                    : 'Die Eingabe wurde an die Versicherung kommuniziert.',
                  disableButton: true,
                  disableApprove: statusHelp.approved,
                  isEditing: false,
                  editFirstname: selectedHelp.vorname,
                  editLastname: selectedHelp.nachname,
                  editAddress: selectedHelp.strasse,
                  editPlz: selectedHelp.plz,
                  editOrt: selectedHelp.ort,
                  editEmail: selectedHelp.email,
                  editBirthday: selectedHelp.geburtsDatum,
                  editPersonalNummer: selectedHelp.personalnummer,
                  disableButton: true,
                  disableApprove: selectedStatus.approved,
                })
              })
            } else {
              this.setState({ statusError: data2.error })
            }
          })
        } else {
          const bavData = data1.bavData
          dispatch(updateBav(data1.bavData))

          const selectedBav =
            bavData[
              bavData
                .map((e) => {
                  return e.id
                })
                .indexOf(selected.id)
            ]
          const bavID = selectedBav.status.id
          const bavStatusID = selectedBav.status.status.id
          axios.get(`/bavstatus?produktid=${selectedBav.produktid}`).then((response) => {
            let allStatiHelp = {}
            let selectedStatus = {}
            const time = moment()
            const sortedResponse = sortBy(response.data.data, (e) => {
              return new moment(e.timestamp, 'DD.MM.YYYY kk:mm:ss')
            })
            const sortedActive = sortedResponse.filter((e) => {
              if (e.status.id == 1) return e
            })
            const sortedMaternity = sortedResponse.filter((e) => {
              if (e.status.id == 2) return e
            })
            const sortedLeave = sortedResponse.filter((e) => {
              if (e.status.id == 3) return e
            })
            const sortedEnd = sortedResponse.filter((e) => {
              if (e.status.id == 4) return e
            })

            if (bavStatusID == 1) {
              allStatiHelp.active = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

              allStatiHelp.maternity =
                sortedMaternity.length > 0 &&
                new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedMaternity[sortedMaternity.length - 1]
                  : null
              if (!!allStatiHelp.maternity) {
                const index = sortedResponse
                  .map((e) => {
                    return e.id
                  })
                  .indexOf(allStatiHelp.maternity.id)
                for (let i = 1; index + i < sortedResponse.length; i++) {
                  if (
                    sortedResponse[index + i].status.id == 1 &&
                    new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                      new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                    )
                  ) {
                    allStatiHelp.maternity.active = sortedResponse[index + i]
                    break
                  }
                }
              }

              allStatiHelp.leave =
                sortedLeave.length > 0 &&
                new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedLeave[sortedLeave.length - 1]
                  : null

              allStatiHelp.end =
                sortedEnd.length > 0 && new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedEnd[sortedEnd.length - 1]
                  : null

              selectedStatus = allStatiHelp.active
            } else if (bavStatusID == 2) {
              allStatiHelp.active =
                sortedActive.length > 0 &&
                new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedActive[sortedActive.length - 1]
                  : null

              allStatiHelp.maternity = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

              if (!!allStatiHelp.maternity) {
                const index = sortedResponse
                  .map((e) => {
                    return e.id
                  })
                  .indexOf(allStatiHelp.maternity.id)
                for (let i = 1; index + i < sortedResponse.length; i++) {
                  if (
                    sortedResponse[index + i].status.id == 1 &&
                    new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                      new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                    )
                  ) {
                    allStatiHelp.maternity.active = sortedResponse[index + i]
                    break
                  }
                }
              }

              allStatiHelp.leave =
                sortedLeave.length > 0 &&
                new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedLeave[sortedLeave.length - 1]
                  : null

              allStatiHelp.end =
                sortedEnd.length > 0 && new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedEnd[sortedEnd.length - 1]
                  : null

              selectedStatus = allStatiHelp.maternity
            } else if (bavStatusID == 3) {
              allStatiHelp.active =
                sortedActive.length > 0 &&
                new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedActive[sortedActive.length - 1]
                  : null

              allStatiHelp.maternity =
                sortedMaternity.length > 0 &&
                new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedMaternity[sortedMaternity.length - 1]
                  : null
              if (!!allStatiHelp.maternity) {
                const index = sortedResponse
                  .map((e) => {
                    return e.id
                  })
                  .indexOf(allStatiHelp.maternity.id)
                for (let i = 1; index + i < sortedResponse.length; i++) {
                  if (
                    sortedResponse[index + i].status.id == 1 &&
                    new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                      new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                    )
                  ) {
                    allStatiHelp.maternity.active = sortedResponse[index + i]
                    break
                  }
                }
              }

              allStatiHelp.leave = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

              allStatiHelp.end =
                sortedEnd.length > 0 && new moment(sortedEnd[sortedEnd.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedEnd[sortedEnd.length - 1]
                  : null

              selectedStatus = allStatiHelp.leave
            } else if (bavStatusID == 4) {
              allStatiHelp.active =
                sortedActive.length > 0 &&
                new moment(sortedActive[sortedActive.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedActive[sortedActive.length - 1]
                  : null

              allStatiHelp.maternity =
                sortedMaternity.length > 0 &&
                new moment(sortedMaternity[sortedMaternity.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedMaternity[sortedMaternity.length - 1]
                  : null
              if (!!allStatiHelp.maternity) {
                const index = sortedResponse
                  .map((e) => {
                    return e.id
                  })
                  .indexOf(allStatiHelp.maternity.id)
                for (let i = 1; index + i < sortedResponse.length; i++) {
                  if (
                    sortedResponse[index + i].status.id == 1 &&
                    new moment(sortedResponse[index + i].datum, 'DD.MM.YYYY').isAfter(
                      new moment(allStatiHelp.maternity.datum, 'DD.MM.YYYY'),
                    )
                  ) {
                    allStatiHelp.maternity.active = sortedResponse[index + i]
                    break
                  }
                }
              }

              allStatiHelp.leave =
                sortedLeave.length > 0 &&
                new moment(sortedLeave[sortedLeave.length - 1].datum, 'DD.MM.YYYY').isAfter(time)
                  ? sortedLeave[sortedLeave.length - 1]
                  : null

              allStatiHelp.end = sortedResponse[sortedResponse.map((e) => e.id).indexOf(bavID)]

              selectedStatus = allStatiHelp.end
            }

            const selectedHelp = {
              ...selectedBav,
              fullname: selectedBav.vorname + ' ' + selectedBav.nachname,
              status: bavStatusID,
            }

            const statusHelp = {
              approved: selectedStatus.approved,
              value: selectedStatus.status.id,
              startDate: selectedStatus.datum,
              startID: selectedStatus.id,
              endDate: !!selectedStatus.active ? selectedStatus.active.datum : null,
              endID: !!selectedStatus.active ? selectedStatus.active.id : null,
              month: moment(selectedStatus.datum, 'DD.MM.YYYY').month() + 1,
              year: moment(selectedStatus.datum, 'DD.MM.YYYY').year(),
            }

            this.setState({
              statusError: '',
              statusSuccess: isSuperAdmin(role)
                ? 'Die Eingabe wurde erfolgreich gespeichert'
                : 'Die Eingabe wurde an die Versicherung kommuniziert.',
              disableButton: true,
              disableApprove: statusHelp.approved,
              selected: selectedHelp,
              status: statusHelp,
              allStati: allStatiHelp,
              isEditing: false,
              editFirstname: selectedHelp.vorname,
              editLastname: selectedHelp.nachname,
              editAddress: selectedHelp.strasse,
              editPlz: selectedHelp.plz,
              editOrt: selectedHelp.ort,
              editEmail: selectedHelp.email,
              editBirthday: selectedHelp.geburtsDatum,
              editPersonalNummer: selectedHelp.personalnummer,
              disableButton: true,
              disableApprove: selectedStatus.approved,
            })
          })
        }
      })
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    bavData: state.bav.bavData,
    role: state.bav.userData.role,
  }
}

export default connect(mapStateToProps)(withRouter(Employees))
