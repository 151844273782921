'use strict'

import { defaultResources as resources } from 'resources'

function Footer() {
  return (
    <div className="Footer">
      <div className="Footer__text">
        <div className="Footer__title">{resources.footer.title}</div>
        <div className="Footer__subtitle">{resources.footer.subtitle}</div>
      </div>

      <div className="Footer__links">
        <a className="Footer__link" href={resources.footer.column3.link3.link} target="_blank" rel="noopener">
          Datenschutz
        </a>

        <a className="Footer__link" href={resources.footer.column3.link4.link} target="_blank" rel="noopener">
          Impressum
        </a>
      </div>
    </div>
  )
}

export default Footer
