/* @flow */
'use strict'


import classnames from 'classnames'

type Props = {
  className?: string,
  color?: String,
  disabled?: boolean,
  link?: string,
  name: string | Object,
  selected?: boolean,
  style?: Object,
  target?: string,
  onClick?: (event: Object) => void,
}

function Button({ className, color, disabled, link, name, selected, style, target, onClick, ...other }: Props) {
  const classes = classnames(['Button', { disabled }, { selected }, className])

  return link ? (
    <a
      className={`button ${classes} ${color ? color : ''}`}
      href={link}
      target={target || '_blank'}
      style={style}
      {...other}
    >
      {name}
    </a>
  ) : (
    <button
      className={`${classes} ${color ? `Button--${color}` : ''}`}
      disabled={disabled || selected ? 'disabled' : false}
      style={style}
      onClick={onClick}
      onTouchStart={() => true}
      {...other}
    >
      {name}
    </button>
  )
}

export default Button
