import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
// import notifier from 'simple-react-notifications'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'

import { General, NoMatch } from 'components/General'
import { BavArea } from 'containers/BavArea'

const muiTheme = createMuiTheme({
  palette: {
    primary: { main: '#0E3F60' },
    primary1Color: '#0E3F60',
    accent1Color: '#468FBF',
  },
  typography: {
    useNextVariants: true,
  },
})

// notifier.configure({
//   autoClose: 4000,
//   closeOnClick: true,
//   pauseOnHover: true,
// })

const AppRouter = ({ history }) => (
  <HelmetProvider>
    <MuiThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <Router history={history}>
          <Switch>
            <Route path="/unternehmensportal" component={BavArea} />
            <Route path="/seite-nicht-gefunden" component={NoMatch} />
            <Route path="/" component={General} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </HelmetProvider>
)

export default AppRouter
