// Babel
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'
import { createBrowserHistory } from 'history'

import configureStore from './store/configureStore'
import AppRouter from './AppRouter'

const store = configureStore()
// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(createBrowserHistory(), store)

render(
  <Provider store={store}>
    <AppRouter history={history} />
  </Provider>,
  document.querySelector('#app'),
)
