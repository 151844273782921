import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { defaultResources } from 'resources'
import { Login, Logout, PasswordRequest, PasswordUpdate, Footer } from 'components/General'
import Logo from 'components/UI/Logo'
import 'stylesheets/utils/variables.scss'
import 'stylesheets/utils/helpers.scss'

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#0E3F60',
      main: '#0E3F60',
      dark: '#0E3F60',
    },
  },
  typography: {
    useNextVariants: true,
  },
})

function General() {
  const match = useRouteMatch()

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className="General">
        <div className="General__content">
          <Logo />

          <Switch>
            <Route path={`${match.url}login`} exact component={Login} />
            <Route path={`${match.url}logout`} exact component={Logout} />
            <Route path={`${match.url}passwort-vergessen`} exact component={PasswordRequest} />
            <Route path={`${match.url}passwort-vergessen/:secret`} exact component={PasswordUpdate} />
            <Redirect exact from="/" to="/login" />
            <Redirect to="/seite-nicht-gefunden" />
          </Switch>
        </div>

        <Footer resources={defaultResources} />
      </div>
    </MuiThemeProvider>
  )
}

export default General
