/* @flow */
'use strict'

import { Component } from 'react'
import TextField from '@material-ui/core/TextField'

import { formatValue } from 'utils/helpers'
import maxLengths from '../../../common/maxLengths.json'

type Props = {
  id?: string,
  className?: string,
  children?: any,
  errorText?: string,
  hintBottom?: string,
  hintBottomColor?: string,
  labelFixed?: string,
  hintSide?: Object,
  maxLength?: number,
  placeholderFixed?: string,
  prefix?: string,
  showFormattedValue?: boolean,
  // NOTE: add further sizes here if required
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'full',
  style?: Object,
  type?: string,
  autoComplete?: string,
  value?: ?number | string,
  onChange: (event: Object, value: number | string) => void,
  onSubmit?: () => void,
}

type State = {
  showFixedPlaceholder: boolean,
}

class Input extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      showFixedPlaceholder: props.value ? true : false,
    }
  }

  // stop rerendering when props don't change
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.value !== nextProps.value ||
      this.props.error !== nextProps.error ||
      this.props.disabled !== nextProps.disabled
    ) {
      return true
    }

    return false
  }

  render() {
    const {
      id,
      className,
      children,
      errorText,
      hintBottom,
      hintBottomColor,
      labelFixed,
      hintSide,
      maxLength,
      placeholderFixed,
      prefix,
      showFormattedValue,
      size,
      style,
      value,
      onChange,
      onBlur,
      onSubmit,
      hintText,
      floatingLabelText,
      ...other
    } = this.props

    const showFixedPlaceholder = this.state.showFixedPlaceholder

    const defaultStyles = { marginTop: 8, marginBottom: 8 }
    const sizeStyles =
      size === 'xxs'
        ? { width: 55 }
        : size === 'xs'
        ? { width: 100 }
        : size === 'sm'
        ? { width: 150 }
        : size === 'md'
        ? { width: 200 }
        : size === 'lg'
        ? { width: 220 }
        : size === 'xl'
        ? { width: 280 }
        : size === 'xxl'
        ? { width: 350 }
        : size === 'xxxl'
        ? { width: 450 }
        : size === 'full'
        ? { width: '100%' }
        : null

    const inputStyles = { ...defaultStyles, ...sizeStyles, ...style }
    const inputClassName = `MuiInput ${prefix ? 'inputAfterPrefix' : 'inputWithoutPrefix'}`

    const defaultMaxLength = showFormattedValue ? 11 : 50
    const getMaxLength = id => {
      // check if we have a max length for the id in our schema
      if (id && maxLengths.benutzer.hasOwnProperty(id)) {
        return maxLengths.benutzer[id]
      }

      // if (id && !maxLengths.benutzer.hasOwnProperty(id)) {
      //   console.log('no maxlength for id', id);
      // }

      return maxLength ? maxLength : defaultMaxLength
    }

    return (
      <div className={className ? `Input text-align-left ${className}` : 'Input text-align-left'}>
        {labelFixed && <div className="labelFixed sm">{labelFixed}</div>}
        {placeholderFixed && showFixedPlaceholder && <div className="placeholderFixed">{placeholderFixed}</div>}
        {prefix && <div className="prefix sm">{prefix}</div>}

        <TextField
          id={id}
          className={inputClassName}
          autoCapitalize="off"
          autoCorrect="off"
          min={0}
          spellCheck="false"
          style={inputStyles}
          label={floatingLabelText}
          placeholder={hintText}
          value={showFormattedValue && typeof value === 'number' ? formatValue(value) : value}
          inputProps={{ maxLength: getMaxLength(id) }}
          onBlur={(event: Object) => {
            const changedValue = typeof value === 'string' ? value.trim() : value
            onChange(event, changedValue)
            if (onBlur) {
              onBlur(event, changedValue)
            } else {
              if (placeholderFixed && !changedValue) {
                this.setState({ showFixedPlaceholder: false })
              }
            }
          }}
          onChange={(event: Object) => {
            const value = event.target.value
            if (showFormattedValue) {
              // only allow numeric values
              const numericValue = Number(value.replace(/\D/g, ''))
              // only allow positive values
              const currentValue = numericValue >= 0 ? numericValue : Math.abs(numericValue)
              onChange(event, currentValue)
            } else {
              onChange(event, value)
            }
          }}
          onFocus={() => {
            if (placeholderFixed) {
              this.setState({ showFixedPlaceholder: true })
            }
          }}
          onKeyDown={(event: Object) => {
            if (event.keyCode === 13 && onSubmit) {
              onSubmit()
            }
          }}
          {...other}
        >
          {children ? children : undefined}
        </TextField>

        {hintSide && <div className="hintSide">{hintSide}</div>}

        {hintBottom && (
          <div className="hintBottom xs" style={{ color: hintBottomColor }}>
            {hintBottom}
          </div>
        )}
      </div>
    )
  }
}

export default Input
