

const SvgExternal = props => (
  <svg viewBox="0 0 1024 1024" {...props}>
    <defs>
      <style>{'.external_svg__cls-1{fill:#1f4868}'}</style>
    </defs>
    <path
      className="external_svg__cls-1"
      d="M908 908H116V116h331v24H140v744h744V572h24v336zM908 437h-24V140H576v-24h332v321z"
    />
    <path className="external_svg__cls-1" d="M247.572 748.455l640.036-628.961 16.822 17.118-640.036 628.96z" />
  </svg>
)

export default SvgExternal
