/* @flow */
'use strict'

import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import mainReducer from './main'
import bavReducer from './bav'

export default combineReducers({
  main: mainReducer,
  bav: bavReducer,
  routing: routerReducer,
})
