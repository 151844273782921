/* @flow */
'use strict'

import { map, findIndex, filter } from 'lodash'

import type { Action } from '../actions/types'

export type User = {
  id: number,
  kundennr: number,
  vorname: string,
  nachname: string,
  fullname: string,
  anrede: string,
  titel: string,
  titel2: string,
  geburtsDatum: string,
  geburtsLand: string,
  geburtsOrt: string,
  nationalitaet: string,
  familienStand: string,
  strasse: string,
  plz: number | string,
  ort: string,
  land: string,
  telefon: string,
  email: string,
  // status === 1 ('Signup abgebrochen')
  // status === 2 ('Dok an Kunde schicken')
  // status === 3 ('Dok bei Kunde')
  // status === 4 ('Dok bei Partner')
  // status === 5 ('Konto eröffnet')
  status: number,
  benutzerstatus: Object,
  memberSince: string,
  lastLogin: string,
  role: number,
  // NOTE: bei jedem neuen Login wird zwischen 'FINABRO Modellrechnung'
  // und 'Gesetzlicher Modellrechnung' gewechselt
  showFinabroModel: boolean,
  konto?: UserKonto,
}

type UserKonto = {
  id: number,
  nummer: string,
  name: string,
  curr: string,
  institut: string,
  iban: string,
  bic: string,
}

type Konto = {
  id: number,
  nummer: string,
  name: string,
  curr: string,
  institut: string,
  iban: string,
  bic: string,
}

export type BavUser = {
  id: number,
  kundennr: number,
  vorname: string,
  nachname: string,
  anrede: string,
  titel: string,
  titel2: string,
  geburtsDatum: string,
  geburtsLand: string,
  geburtsOrt: string,
  nationalitaet: string,
  familienStand: string,
  strasse: string,
  doorNumber: string,
  staircase: string,
  houseNumber: string,
  plz: number,
  ort: string,
  land: string,
  telefon: string,
  email: string,
  product: Product,
}

export type Company = {
  id: number,
  name: string,
  strasse: string,
  plz: number,
  ort: string,
  land: string,
}

export type Partner = {
  id: number,
  name: string,
  konto: Konto,
}

export type Product = {
  id: number,
  name: string,
  start: string,
  ende: string,
  partner: Partner,
  mantel: string,
  produktmantel: Object,
  kategorie: string,
  produktkategorie: Object,
  portfoliorisiko: number,
  status: string,
  waehrung: string,
  zielSumme: number,
  zielRente: number,
  bewertungsdatum: string,
  gesamtwert: number,
  depotwert: number,
  kontostand: number,
  gewinn: number,
  rendite: number,
  sumEinzahlungen: number,
  musterPortfolio: Portfolio,
  konto: Konto,
  depot: Depot,
  allocation: Array<Allocation>,
  allocationGroup: Array<Allocation>,
  performance: Array<Performance>,
  sparplan: SavingsPlan,
  sparplaene: Array<SavingsPlan>,
  beneficiary?: Object,
  sammelkonto?: boolean,
}

type State = {
  userData: ?User,
  bavData: ?Array<BavUser>,
  companiesData: ?Array<Company>,
}

const initialState: State = {
  userData: {
    id: 0,
    vorname: '',
    nachname: '',
    fullname: '',
    anrede: '',
    titel: '',
    titel2: '',
    geburtsDatum: '',
    geburtsLand: '',
    geburtsOrt: '',
    nationalitaet: '',
    familienStand: '',
    strasse: '',
    plz: '',
    ort: '',
    land: '',
    telefon: '',
    email: '',
    memberSince: '',
    lastLogin: '',
    showFinabroModel: true,
    konto: {
      id: 0,
      nummer: '',
      name: '',
      curr: '',
      institut: '',
      iban: '',
      bic: '',
    },
  },
  bavData: [],
  companiesData: [],
  partners: [],
}

export default function bavReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case 'FETCH_INITIAL_BAV':
      return fetchInitialBav(state, action)
    case 'UPDATE_BAV':
      return updateBav(state, action)
    case 'UPDATE_COMPANIES':
      return updateCompanies(state, action)
    case 'LOGOUT':
      return logoutUser()
    default:
      return state
  }
}

// reducers
function fetchInitialBav(state, action): State {
  const { userData, bavData, companiesData, partnersData } = action.payload

  return {
    ...state,
    userData: {
      ...userData,
      fullname: `${userData.vorname} ${userData.nachname}`,
    },
    bavData: map(bavData, (bav) => {
      return {
        ...bav,
        fullname: `${bav.vorname} ${bav.nachname}`,
        sammelkonto: !!bav.sammelkonto ? JSON.parse(bav.sammelkonto) : false,
      }
    }),
    companiesData: map(companiesData, (company) => {
      const unternehmenBav = filter(bavData, (bav) => bav.unternehmen === company.name)
      return {
        ...company,
        hasFLV: !!company.versicherung && company.versicherung.indexOf('Fondsgebundene LV') > -1,
        hasKLV: !!company.versicherung && company.versicherung.indexOf('Klassische LV') > -1,
        isUmwandlung: filter(unternehmenBav, (person) => !!person.sammelkonto).length > 0,
      }
    }),
    partners: partnersData,
  }
}

function updateBav(state, action): State {
  const bavData = action.payload

  return {
    ...state,
    bavData: map(bavData, (bav) => {
      return {
        ...bav,
        fullname: `${bav.vorname} ${bav.nachname}`,
        sammelkonto: !!bav.sammelkonto ? JSON.parse(bav.sammelkonto) : false,
      }
    }),
  }
}

export function updateCompanies(state, action): State {
  const { bavData, companiesData } = action.payload
  return {
    ...state,
    companiesData: map(companiesData, (company) => {
      const unternehmenBav = filter(bavData, (bav) => {
        return bav.unternehmen == company.name
      })
      return {
        ...company,
        hasFLV: !!company.versicherung && company.versicherung.indexOf('Fondsgebundene LV') > -1,
        hasKLV: !!company.versicherung && company.versicherung.indexOf('Klassische LV') > -1,
        isUmwandlung:
          filter(unternehmenBav, (person) => {
            return !!person.sammelkonto
          }).length > 0,
      }
    }),
  }
}

export function updatePartners(state, action): State {
  const { partnersData } = action.payload

  return {
    ...state,
    partners: partnersData,
  }
}

function logoutUser(): State {
  return {
    ...initialState,
  }
}
