'use strict'

import { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import { login } from 'core/api'
import { defaultResources as resources } from 'resources'
import { fetchInitialBav } from 'actions/bav'

import Modal from 'components/UI/Modal'
import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import LoadingSpinner from 'components/UI/LoadingSpinner'

import './Login.scss'

type Props = {
  resources: ?Object,
  match: Object,
  location: Object,
  history: Object,
}

type State = {
  email: ?string,
  password: ?string,
  error: ?string,
  showResumeModal: boolean,
  resumeModalText: ?string | Object,
  isLoading: boolean,
}

class Login extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      email: null,
      password: null,
      error: null,
      showResumeModal: false,
      resumeModalText: null,
      isLoading: false,
    }

    this.onLogin = this.onLogin.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    document.body.classList.add('login')
  }

  onSubmit(event: Object): void {
    const { email, password } = this.state

    if (email && password) {
      this.setState({
        error: null,
        isLoading: true,
      })

      login(email, password, this.onLogin)
    } else {
      this.setState({
        error: resources.login.emptyError,
        isLoading: false,
      })
    }
  }

  onLogin(data: Object): void {
    const { history, dispatch } = this.props

    console.log('data', data)

    if (data.success) {
      if (data.bavModus) {
        const { userData, bavData, companies, partners } = data
        dispatch(fetchInitialBav(userData, bavData, companies, partners))
        history.push('/unternehmensportal')
      }
    } else {
      // errNum 411 / status 1 -> resume signup
      // errNum 412 or 413 / status 2 or 3 -> show info text
      // errNum 414 / status 4 -> show other info text
      // errNum 427 -> KLV User locked out
      // errNum 428 -> AGBs not confirmed
      // status 5, 6, undefined -> show error
      console.error(`Fehlercode ${data.errNum}`)

      this.setState({
        isLoading: false,
        error: data.errDescription,
      })
    }
  }

  render() {
    const { error, email, password, showResumeModal, resumeModalText, isLoading } = this.state

    return (
      <div className="Login container">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        </Helmet>

        <div className="column">
          <h1 className="Login__title">{resources.login.title}</h1>

          {!isLoading ? (
            <>
              <div className="credentials column">
                <Input
                  id="email"
                  type="email"
                  autoComplete="email"
                  floatingLabelText={resources.login.email.label}
                  hintText={resources.login.email.text}
                  maxLength={50}
                  tabIndex={1}
                  value={email ? email : ''}
                  onChange={(event: Object, text: string) => this.setState({ email: text })}
                />

                <Input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  floatingLabelText={resources.login.password.label}
                  hintText={resources.login.password.text}
                  hintSide={
                    !password && (
                      <Link id="subLink" to="/passwort-vergessen" className="inputLink">
                        {resources.login.forgotPassword}
                      </Link>
                    )
                  }
                  maxLength={50}
                  tabIndex={2}
                  value={password || ''}
                  onChange={(event: Object, text: string) => this.setState({ password: text })}
                  onSubmit={this.onSubmit}
                />
              </div>

              {error && <p className="error">{error}</p>}

              <Button className="Login__button button1" name="Anmelden" tabIndex={3} onClick={this.onSubmit} />

              <Modal
                contentLabel="Info"
                isOpen={showResumeModal}
                onRequestClose={() => this.setState({ showResumeModal: false })}
              >
                <div>{resumeModalText}</div>
              </Modal>
            </>
          ) : (
            <div className="container">
              <LoadingSpinner text="Sie werden angemeldet..." />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: Object) => {
  return {}
}

export default connect(mapStateToProps)(withRouter(Login))
