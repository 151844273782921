/* @flow */
'use strict'

import React from 'react'
import Button from '@material-ui/core/Button'

type Props = {
  username: ?string,
  onLogout?: () => void,
}

function Header({ username, onLogout }: Props) {
  return (
    <div id="headerBlock">
      <img src="/assets/images/finabro-logo.png" className="logo" />
      <div id="headerItems">
        <span>{username}</span>
        <Button
          variant="outlined"
          color="primary"
          className="button primary outlined"
          style={{ width: '100px', height: '40px' }}
          onClick={onLogout}
        >
          Logout
        </Button>
      </div>
    </div>
  )
}

export default Header
