'use strict'

import LogoFinabro from 'components/UI/SVG/LogoFinabro'

import './Logo.scss'

type Props = {
  link: string,
}

function Logo({ link }: Props) {
  return (
    <div className="Logo">
      <a href={link} target="_blank" rel="noopener">
        <LogoFinabro />
      </a>
    </div>
  )
}

export default Logo
