/* @flow */
'use strict'

import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'

type State = {
  focusedOnce: boolean,
}

class PasswordInput extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)
    this.state = { value: props.value }
    this.handleTextBlur = this.handleTextBlur.bind(this)
    this.getPasswordWarning = this.getPasswordWarning.bind(this)
  }

  handleTextBlur(event: Object) {
    this.setState({ focusedOnce: true })

    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  getPasswordWarning(password) {
    const minLength = this.props.minLength || 11
    if (!password || password.length < minLength) {
      return this.props.lengthErrorText || 'Passwort zu kurz'
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
      return 'Passwort muss aus Großbuchstaben, Kleinbuchstaben und Zahlen bestehen'
    } else {
      return null
    }
  }

  isValidPassword(password) {
    return !this.getPasswordWarning(password)
  }

  getHintBottom(warningText) {
    if (warningText) {
      return (
        <span className="hint">
          <span className="warningText">{warningText}</span>
        </span>
      )
    } else {
      return null
    }
  }

  render() {
    const {
      value,
      minLength,
      lengthErrorText,
      minScore,
      scoreWords,
      scoreErrorText,
      overrideFocusedOnce,
      onChange,
      onBlur,
      errorLabelStyle,
      ...other
    } = this.props

    const warningText = this.getPasswordWarning(value)
    const isWarningShown = !!((this.props.overrideFocusedOnce || this.state.focusedOnce) && warningText)

    const hintBottom = this.getHintBottom(isWarningShown ? warningText : null)
    const hintBottomColor = isWarningShown ? errorLabelStyle.color : undefined

    return (
      <span className="PasswordInput">
        <TextField
          onChange={(event) => onChange(event, this.isValidPassword(event.target.value))}
          onBlur={this.handleTextBlur}
          {...other}
          error={isWarningShown}
          value={value}
        />

        {hintBottom && (
          <div className="hintBottom xs" style={{ color: hintBottomColor }}>
            {hintBottom}
          </div>
        )}
      </span>
    )
  }
}

export default PasswordInput
