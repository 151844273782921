/* @flow */
'use strict'

import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import isEmail from 'validator/lib/isEmail'

import { resetPassword } from '../../../core/api'

import Button from '../../UI/Button'
import Input from '../../UI/Input'

type Props = {}

type State = {
  email: ?string,
  hideNextStep: boolean,
  disableNextStep: boolean,
  showEmailError: boolean,
  success: boolean,
}

const EMAIL_FIELD = 'PasswordRequestEmailField'

class PasswordRequest extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      email: null,
      hideNextStep: false,
      disableNextStep: true,
      showEmailError: false,
      success: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onContinue = this.onContinue.bind(this)
  }

  render() {
    const { email, hideNextStep, disableNextStep, showEmailError, success } = this.state
    const emailErrorText = disableNextStep && showEmailError ? 'Bitte eine gültige E-Mail-Adresse verwenden' : undefined

    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        </Helmet>

        <div className="PasswordRequest container">
          <div className="column">
            <h1 className="PasswordRequest__title">Passwort zurücksetzen</h1>

            <h5 className="PasswordRequest__subtitle">
              Bitte geben Sie Ihre E-Mail Adresse ein, um Ihr Passwort zurückzusetzen.
            </h5>

            <div className="credentials column">
              <Input
                id="password-request-email"
                type="email"
                autoComplete="email"
                className={EMAIL_FIELD}
                errorText={emailErrorText}
                floatingLabelText="E-Mail"
                hintText="E-Mail-Adresse eingeben"
                maxLength={50}
                readOnly={hideNextStep}
                tabIndex={1}
                value={email ? email : ''}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
              />
            </div>

            {success && (
              <p className="success" style={{ marginBottom: 30 }}>
                Falls die angegebene E-Mail-Adresse in unserem System vorhanden ist,
                <br />
                bekommen Sie in Kürze einen Link, um Ihr Passwort zurückzusetzen.
              </p>
            )}

            {!hideNextStep && (
              <Button
                name="Zurücksetzen"
                className={`button1 ${disableNextStep ? 'disabled' : ''}`}
                tabIndex={3}
                onClick={this.onContinue}
              />
            )}

            <Link to="/login" className="signup-link xs">
              zurück zum Login
            </Link>
          </div>
        </div>
      </>
    )
  }

  /// Event Handlers

  onChange(event: Object, email: string) {
    const disableNextStep = !this.isValidEmail(email)

    if (this.state.showEmailError) {
      const showEmailError = disableNextStep

      this.setState({
        email,
        disableNextStep,
        showEmailError,
      })
    } else {
      this.setState({
        email,
        disableNextStep,
      })
    }
  }

  onSubmit(event: Object): void {
    const { email, hideNextStep } = this.state
    const isValid = this.isValidEmail(email)

    if (email && isValid) {
      if (!hideNextStep) {
        resetPassword(email, (data: Object) => {
          this.setState({
            hideNextStep: true,
            success: true,
          })
        })
      }
    } else {
      this.setState({
        disableNextStep: true,
        showEmailError: true,
      })
      this.focusEmailField()
    }
  }

  onContinue(event: Object): void {
    const email = this.state.email
    const disableNextStep = !this.isValidEmail(email)
    const showEmailError = disableNextStep

    this.setState({
      disableNextStep,
      showEmailError,
    })

    if (disableNextStep) {
      this.focusEmailField()
    } else {
      this.onSubmit(event)
    }
  }

  isValidEmail(email: ?string): boolean {
    return typeof email === 'string' && isEmail(email)
  }

  focusEmailField(): void {
    document.querySelector(`.${EMAIL_FIELD} input`).focus()
  }
}

export default PasswordRequest
