/* @flow */
'use strict'

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  menuItems: Array<Object>
}

type State = {}


class MainMenu extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // this.state = {}

    // const _Component = (this: any)
    // _Component.onEvent = this.onEvent.bind(this)
  }

  /// Mounting
  // componentDidMount() {}
  // componentWillUnmount() {}

  /// Updating
  // shouldComponentUpdate(nextProps: Props, nextState: State) {}
  // componentDidUpdate(prevProps: Props, prevState: State) {}

  render() {
    const menuItems = this.props.menuItems

    return (
      <div id="menuBlock">
      { menuItems.map((menu, index) => {
        if ( menu.path != '/'
          && window.location.pathname.indexOf(menu.path) > -1
          || window.location.pathname == menu.path ) {
          return (<img id="menuBackground" src={menu.image} key={index}/>)
        }
      }) }
        <div id="menuInner">
          { menuItems.map((menu, index) => {
            return (
              <Link
                key={index}
                to={menu.path}
                className={
                  menu.path != '/'
                  && window.location.pathname.indexOf(menu.path) > -1
                  || window.location.pathname == menu.path
                  ? 'blockLink selected'
                  : 'blockLink'
                }
              >
                <span>{menu.name}</span><img src={menu.image} />
              </Link>
            )
          }) }
        </div>
      </div>
    )
  }


  /// Event Handlers
}

export default MainMenu
