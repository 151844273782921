import { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { checkPasswordReset, updatePassword } from 'core/api'

import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import PasswordInput from 'components/UI/PasswordInput/PasswordInput'

type Props = {
  match: Object,
  location: Object,
  history: Object,
}

type State = {
  disableNextStep: boolean,
  showErrors: boolean,
  new_password: ?string,
  password2: ?string,
  email: ?string,
  secret: ?string,
  success: ?string,
  error: ?string,
  isSecurePassword: boolean,
}

const NEW_PASSWORD_FIELD = 'NewPasswordField'
const CONFIRM_PASSWORD_FIELD = 'ConfirmPasswordField'
const MIN_PASSWORD_LENGTH = 11
const UUID_LENGTH = 36
const COLOR_DANGER = '#EA1818'
const styles = {
  error: {
    color: COLOR_DANGER,
  },
}

// accessible via URL: http://localhost:3000/passwort-vergessen/NmQwYWU5YWEtNmQ5MC00MjEwLWI3YWQtYjNhYjkyYjgyNmM3
class PasswordUpdate extends Component {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    const match = props.match
    const decodedData = typeof match.params.secret !== 'undefined' ? atob(match.params.secret) : null

    this.state = {
      disableNextStep: true,
      showErrors: false,
      new_password: '',
      password2: null,
      email: decodedData ? decodedData.slice(UUID_LENGTH) : null,
      secret: decodedData ? decodedData.slice(0, UUID_LENGTH) : null,
      success: null,
      error: null,
      isSecurePassword: false,
    }

    this.onChangeNewPassword = this.onChangeNewPassword.bind(this)
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this)
    this.onContinue = this.onContinue.bind(this)
    this.shouldDisableNextStep = this.shouldDisableNextStep.bind(this)
  }

  componentDidMount() {
    const history = this.props.history
    const { email, secret } = this.state
    if (email && secret) {
      checkPasswordReset(email, secret, (data: Object) => {
        if (data && !data.success) {
          history.push('/passwort-vergessen')
        } else {
          // NOTE: valid password reset call, awaiting user to change password
        }
      })
    } else {
      history.push('/passwort-vergessen')
    }
  }

  render() {
    const { disableNextStep, showErrors, email, new_password, password2, success, error } = this.state

    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        </Helmet>

        <div className="PasswordUpdate container">
          <div className="column">
            <h1>Passwort aktualisieren</h1>

            {!success && (
              <>
                <div className="credentials column">
                  <Input
                    className="PasswordUpdate__email"
                    type="email"
                    disabled
                    floatingLabelText="E-Mail"
                    value={email}
                  />

                  <PasswordInput
                    id="passwort"
                    type="password"
                    autoComplete="new-password"
                    className={NEW_PASSWORD_FIELD}
                    floatingLabelText="Neues Passwort*"
                    value={new_password ? new_password : ''}
                    errorLabelStyle={styles.error}
                    minLength={MIN_PASSWORD_LENGTH}
                    lengthErrorText={'Bitte mindestens ' + MIN_PASSWORD_LENGTH + ' Zeichen verwenden.'}
                    onChange={(event: Object, isSecure: boolean) => {
                      this.setState(
                        {
                          new_password: event.target.value,
                          isSecurePassword: isSecure,
                        },
                        () => this.shouldDisableNextStep(),
                      )
                    }}
                  />

                  <Input
                    id="passwort-confirm"
                    type="password"
                    autoComplete="new-password"
                    className={CONFIRM_PASSWORD_FIELD}
                    hintBottom={
                      password2 && new_password !== password2 ? 'Die Passwörter stimmen nicht überein.' : undefined
                    }
                    hintBottomColor={COLOR_DANGER}
                    floatingLabelText="Passwortwiederholung*"
                    value={password2 ? password2 : ''}
                    onChange={this.onChangeConfirmPassword}
                  />
                </div>

                <div className="row-left-top">
                  <p className="hint xs">
                    Bitte mindestens 11 Zeichen bestehend aus Kleinbuchstaben, Großbuchstaben und Zahlen eingeben.
                  </p>
                </div>
              </>
            )}

            {success && <p className="success">{success}</p>}
            {error && <p className="error">{error}</p>}

            {!success && (
              <Button
                className={`button1 ${disableNextStep ? 'disabled' : ''}`}
                name="Speichern"
                tabIndex={3}
                onClick={this.onContinue}
                disabled={disableNextStep}
              />
            )}

            <Link to="/login" className="xs">
              Zurück
            </Link>
          </div>
        </div>
      </>
    )
  }

  onChangeNewPassword(event: Object, value: string) {
    const password2 = this.state.password2
    this.setState({ new_password: value }, () => {
      this.shouldDisableNextStep()
    })
  }

  onChangeConfirmPassword(event: Object, value: string) {
    const new_password = this.state.new_password

    this.setState({ password2: value }, () => {
      this.shouldDisableNextStep()
    })
  }

  onContinue(event: Object): void {
    const { email, new_password, secret } = this.state

    if (email && new_password && secret) {
      updatePassword(email, new_password, secret, (data: Object) => {
        if (data && data.success) {
          this.setState(
            {
              success: 'Sie haben Ihr Passwort erfolgreich zurückgesetzt. Sie werden nun zum Login weitergeleitet.',
              error: null,
            },
            () => {
              setTimeout(() => this.props.history.push('/login'), 3600)
            },
          )
        } else {
          const error =
            data.error && data.error.length > 0 ? data.error : 'Ein Fehler ist aufgetreten. Bitte kontaktieren Sie uns.'

          this.setState({
            success: null,
            error,
          })
        }
      })
    }
  }

  shouldDisableNextStep(): void {
    const { new_password, password2, isSecurePassword } = this.state

    const disableNextStep =
      !(
        new_password &&
        new_password.length >= MIN_PASSWORD_LENGTH &&
        password2 &&
        password2.length >= MIN_PASSWORD_LENGTH &&
        new_password === password2
      ) || !isSecurePassword

    this.setState({ disableNextStep })
  }
}

export default withRouter(PasswordUpdate)
