/* @flow */
'use strict'

import { applyMiddleware, compose, createStore } from 'redux'

import thunk from 'redux-thunk'
import promise from 'redux-promise'
import createLogger from 'redux-logger'

import reducers from '../reducers'


const initialState: ?Object = undefined
const logger = createLogger()


export default function configureStore(state: ?Object = initialState): Object {
  let store;

  if (!PROD) {
    console.debug('Creating store with devTools.');
    store = createStore(
      reducers,
      state,
      compose(
        applyMiddleware(thunk, promise, logger),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
      )
    );

    // NOTE: https://github.com/reactjs/react-redux/releases/tag/v2.0.0
    // NOTE: This doesn't seem to work yet !
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      (module.hot: any).accept('../reducers', () => {
        const nextRootReducer = require('../reducers/index');
        store.replaceReducer(nextRootReducer)
      })
    }
  } else {
    store = createStore(
      reducers,
      state,
      compose(
        applyMiddleware(promise),
      )
    )
  }

  return store
}
